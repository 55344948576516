import moment from 'moment';

import { ProfileObjectPayload } from 'state/account/account.services';
import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';

import { DependentsPricingData } from 'types/family-account';
import { RefillRxs } from 'types/order-prescription';
import { PrescriptionCardProps, PrescriptionObjectPayload, RxDetails } from 'types/prescription';

import {
    RX_ORDER_LINE_QUEUE_STATUS,
    RX_STATUS,
    RX_STATUS_PAYLOAD,
    RX_WEB_ELIGIBILITY_STATUS
} from 'enums/prescription';

import { findDrugInList, getDrugLookupData } from 'util/drug';
import { isDeepEqual } from 'util/object';
import { prescriptionPayloadToProps } from 'util/payload-to-props';
import { isOnDemandPlan } from 'util/plans';

import storageHelper from './storageHelper';

export function addDays(date: string, days: number): Date {
    const origDate = new Date(date);
    origDate.setDate(origDate.getDate() + days);
    return origDate;
}

export function isShipStatusExpired(shipDate: string): boolean {
    if (!shipDate) return false;
    const showShippedStatusDays = 14;
    const shippedExpirationDate = addDays(shipDate, showShippedStatusDays);
    const today = new Date(Date.now());
    return !!(shippedExpirationDate < today);
}

/**
 * Function to check the order is on hold or ordered/shipped state
 * @param orderLineQueueStatus - Order Line queue status of prescription
 * @returns boolean
 */
export const isOrderLineQueued = (orderLineQueueStatus: RX_ORDER_LINE_QUEUE_STATUS): boolean => {
    if (!orderLineQueueStatus) {
        return true;
    }
    return [
        RX_ORDER_LINE_QUEUE_STATUS.SHIPPED,
        RX_ORDER_LINE_QUEUE_STATUS.NOT_FOUND,
        RX_ORDER_LINE_QUEUE_STATUS.OPEN
    ].includes(orderLineQueueStatus);
};

export const isRxOrderedInProgress = (rx: RxDetails) => {
    return (
        rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
        (rx.itemInWorkflow === true ||
            (isOrderLineQueued(rx.orderLineQueueStatus) &&
                rx.itemInWorkflow === false &&
                !isShipStatusExpired(rx.lastFillDate)))
    );
};

export const isRxOnHold = (rx: RxDetails) => {
    return rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE && rx.orderQueueStatus === 'HOLD';
};

export function isRxExpired(expirationDate: string): boolean {
    const today = moment(new Date()).format('MM/DD/YYYY');
    return moment(expirationDate).isBefore(today, 'day');
}

export function isRxCloseToExpire(expirationDate: string): boolean {
    const inputDate = moment(expirationDate, 'MM/DD/YYYY');
    const today = moment(new Date()).format('MM/DD/YYYY');

    if (inputDate.isAfter(today) && inputDate.diff(today, 'days') <= 20) {
        return true;
    }
    return false;
}

export function isRxExpiresToday(expirationDate: string): boolean {
    const inputDate = moment(expirationDate, 'MM/DD/YYYY');
    const today = moment();
    return inputDate.isSame(today, 'day');
}

export function isRxRefillTooSoonStatus(rx: RxDetails): boolean {
    return (
        rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
        rx.nextFillDate !== undefined &&
        rx.itemInWorkflow === false &&
        (isShipStatusExpired(rx.lastFillDate) as boolean)
    );
}

export function isRxRefillAvailableStatus(rx: RxDetails): boolean {
    return rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.ELIGIBLE && rx.rxStatus !== RX_STATUS_PAYLOAD.PROFILED;
}

export function isRxOutOfRefillsStatus(rx: RxDetails): boolean {
    return (
        rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ &&
        !isRxExpired(rx.rxExpirationDate) &&
        Number(rx.fillsRemaining) === 0
    );
}

export function isRxExpiredStatus(rx: RxDetails): boolean {
    return rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ && isRxExpired(rx.rxExpirationDate);
}

export function isRxNew(rx: RxDetails): boolean {
    const rxIdentifier = rx.rxNumber;
    const { prescriptions } = storageHelper.local.getNewRxs();

    if (prescriptions) {
        return prescriptions.includes(rxIdentifier);
    }

    return false;
}

export function hasPlanAutoRefillFlag(
    epostNum: string,
    profileObject?: ProfileObjectPayload | Partial<ProfileObjectPayload>
): boolean {
    if (profileObject && epostNum === profileObject.epostPatientNum && profileObject.patientPlanAutoRefillFlag) {
        return profileObject.patientPlanAutoRefillFlag;
    }

    if (profileObject && profileObject.isCaregiver && profileObject.dependents) {
        const dependentAccount = profileObject.dependents.find((dependent) => dependent.epostPatientNum === epostNum);
        return dependentAccount ? dependentAccount.patientPlanAutoRefillFlag : false;
    }

    return false;
}

// Function that validates whether an rx status should show the
// add to cart button enabled
export const IsAddToCartCTAEnabled = (status: RX_STATUS) => {
    return [RX_STATUS.NEW_RX, RX_STATUS.REFILL_AVAILABLE, RX_STATUS.EXPIRED, RX_STATUS.OUT_OF_REFILLS].includes(status);
};

// Extract the data of an Rx as AllRxs API should return it
// Removing the properties that we added when processing the rx data
export const getOriginalRxObjectFromCard = (prescription: PrescriptionCardProps): PrescriptionObjectPayload => {
    const { fullRxItem } = prescription;
    const { rxSubStatus, realRxCardStatus, rxCardStatus, ...prescriptionObject } = fullRxItem;
    return prescriptionObject;
};

// Comparing 2 objects of type PrescriptionObjectPayload to ensure they are deeply equal
export const isRxListEqual = (rxList1: PrescriptionObjectPayload[], rxList2: PrescriptionObjectPayload[]) => {
    const sortByRxNum = (rx: PrescriptionObjectPayload, rx2: PrescriptionObjectPayload) =>
        Number(rx.rxNumber) - Number(rx2.rxNumber);
    return isDeepEqual(rxList1.sort(sortByRxNum), rxList2.sort(sortByRxNum));
};

// Compare 2 objects of type PrescriptionCardProps to ensure they are equal
export const isRxCardsListEqual = (rxList1: PrescriptionCardProps[], rxList2: PrescriptionCardProps[]) =>
    isRxListEqual(rxList1.map(getOriginalRxObjectFromCard), rxList2.map(getOriginalRxObjectFromCard));

// Get Drug Code
export const getRxDrugCode = (prescription: RxDetails): string =>
    prescription.dispensedProductNumber !== ''
        ? prescription.dispensedProductNumber
        : prescription.writtenProductNumber !== ''
        ? prescription.writtenProductNumber
        : '';

// Map the prescriptions received from API into actual prescription card objects
export const mapRxPrescriptionCards = (
    prescriptions: RxDetails[],
    familyPricingData: DependentsPricingData[],
    drugDiscountPrices: DrugWithDiscountPrice[],
    cartItems?: RefillRxs[],
    selectedMember?: string | null,
    mainUserZipCode?: string | null,
    cartZipCode?: string | null
): PrescriptionCardProps[] =>
    prescriptions.map((prescription) => {
        const patientPricingData: DependentsPricingData | undefined = familyPricingData.find(
            (dependent) => dependent.epostPatientNum === prescription.epostPatientNum
        );

        const planAlias = patientPricingData?.planAlias;
        const planType = patientPricingData?.planType;
        const prescriptionCardProps = prescriptionPayloadToProps(prescription, planAlias, isOnDemandPlan(planType));

        let price;

        if (patientPricingData && drugDiscountPrices.length > 0) {
            const drugLookupData = getDrugLookupData(
                prescription,
                familyPricingData,
                selectedMember,
                mainUserZipCode,
                cartZipCode,
                cartItems
            );

            const drugPrice = findDrugInList(drugLookupData, drugDiscountPrices);

            price = drugPrice?.price;
        }

        const showPrice = !!prescriptionCardProps.priceEligible && !!price && price !== 'NA' && Number(price) !== 0;

        return {
            ...prescriptionCardProps,
            ...patientPricingData,
            ...{ price, showPrice }
        };
    });

export const debugRxCard = (card: RxDetails) => {
    console.group(`${card.dispensedProductName}`);
    console.log('Prescription Card: ' + card.dispensedProductName);
    console.log('rxStatus: ' + card.rxStatus);
    console.log('itemInWorkflow: ' + card.itemInWorkflow);
    console.log('orderLineQueueStatus: ' + card.orderLineQueueStatus);
    console.log('nextFillDate: ' + card.nextFillDate);
    console.log('lastFillDate: ' + card.lastFillDate);
    console.log('shipStatusExpirationDate: ' + addDays(card.lastFillDate, 14).toLocaleDateString('en-US'));
    console.log('isShipStatusExpired: ' + isShipStatusExpired(card.lastFillDate));
    console.log('rxCardStatus:', card.rxCardStatus);
    console.log('realRxCardStatus:', card.realRxCardStatus);
    console.log(card);
    console.groupEnd();
};
