import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { TFunction } from 'gatsby-plugin-react-i18next';

import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';
import { DrugDiscountPriceResponse, DrugLookupObjectPayload } from 'state/drug/drug.services';

import { DependentsPricingData } from 'types/family-account';
import { RefillRxs } from 'types/order-prescription';
import { RxDetails } from 'types/prescription';

import { BIRDI_PLANS } from 'enums/plans';

import { birdiPriceRxLineErrorCodes, RxLineErrorCode } from 'util/cart';
import { getRxDrugCode } from 'util/prescription';
import { doesPlanAllowsPricing } from 'util/pricing';

export function formatDrugName(t: TFunction<'translation'>, drug: DrugLookupObjectPayload): string {
    if (drug.brandDrugName && drug.brandDrugName !== drug.onSaleDrugName && drug.brandNameCode === 'G') {
        return t('prescriptionInfoForm.drugLookup.hasGeneric', {
            drugName: drug.onSaleDrugName,
            brandName: drug.brandDrugName
        });
    } else {
        return drug.onSaleDrugName ? drug.onSaleDrugName : drug.drugName;
    }
}

// Function that returns a drug found in the history
export const findDrugInList = (
    drug: DrugWithDiscountPrice,
    drugHistory: DrugWithDiscountPrice[]
): DrugWithDiscountPrice | undefined =>
    drugHistory.find(
        (item: DrugWithDiscountPrice) =>
            item.drugCode === drug.drugCode &&
            item.planAlias === drug.planAlias &&
            drug.quantity === item.quantity &&
            item.zipCode === drug.zipCode
    );

export const getDrugPricingPlanAlias = ({
    rxNumber,
    pricingDetails,
    cartItems,
    validateRxLineErrors = false
}: {
    rxNumber?: string;
    pricingDetails?: DependentsPricingData;
    cartItems?: RefillRxs[];
    validateRxLineErrors?: boolean;
}) => {
    let clientAcCode;

    if (pricingDetails?.planAlias === BIRDI_PLANS.BRD_02 && pricingDetails.isOnDemandPlan) {
        clientAcCode = BIRDI_PLANS.BRD_01;
    } else {
        clientAcCode = pricingDetails?.planAlias;
    }

    if (validateRxLineErrors) {
        // If plan doesn't allow pricing, is not BRD01, BRD02
        // Which means that the plan is insurance
        const hasInsurance = !doesPlanAllowsPricing(clientAcCode);

        // Birdi Price selection for insured accounts
        // Find insurance rxLineErrors
        const findRxLineError = cartItems?.find((f) => f.rxNumber === rxNumber)?.rxLineError;
        // 40, 65, 70 errors
        const hasCashPriceErrors = birdiPriceRxLineErrorCodes.includes(findRxLineError as RxLineErrorCode);

        // If the user is trying to get prices from the cart
        // but is an insurance user, or if is an insurance user
        // and the ALLOW_INSURED_BIRDI_PRICE feature flag is on
        // we default the plan to BRD01
        if (hasInsurance && (hasCashPriceErrors || ALLOW_INSURED_BIRDI_PRICE)) {
            clientAcCode = BIRDI_PLANS.BRD_01;
        }
    }

    return clientAcCode;
};

export const getDrugPricingZipCode = (
    pricingDetails?: DependentsPricingData,
    mainUserZipCode?: string | null,
    cartItems?: RefillRxs[],
    cartZipCode?: string | null,
    isTransferFlow = false
) => {
    // Set zipcode value
    let zipCode = pricingDetails?.zipCode;

    if (!pricingDetails?.zipCode && !!mainUserZipCode) {
        zipCode = mainUserZipCode;
    }

    // If the user is not transfer flow, take
    // the cart zipcode first.
    if (!isTransferFlow && cartItems && cartItems?.length > 0 && !!cartZipCode) {
        zipCode = cartZipCode;
    }

    return zipCode;
};

export const getDrugLookupData = (
    prescription: RxDetails,
    familyPricingDetails: DependentsPricingData[],
    selectedMember?: string | null,
    mainUserZipCode?: string | null,
    cartZipCode?: string | null,
    cartItems?: RefillRxs[],
    validateRxLineErrors = false
): DrugWithDiscountPrice => {
    // Get the epostPatientNum number
    const isTransferFlow = !!selectedMember;
    const epostPatientNum = isTransferFlow ? selectedMember : prescription.epostPatientNum;

    // We look for the pricing details of the patient
    const pricingDetails = familyPricingDetails.find((dependent) => epostPatientNum === dependent.epostPatientNum);
    const planAlias = getDrugPricingPlanAlias({
        rxNumber: prescription.rxNumber,
        pricingDetails,
        cartItems,
        validateRxLineErrors
    });

    // Return object that will be used for Drug lookup
    return {
        drugCode: getRxDrugCode(prescription),
        quantity: prescription.fillQuantity,
        daysSupply: prescription.fillDaysSupply,
        planAlias,
        gpi: prescription.genericProductIndicator,
        rxNumber: prescription.rxNumber,
        zipCode: getDrugPricingZipCode(pricingDetails, mainUserZipCode, cartItems, cartZipCode, isTransferFlow)
    };
};

// Function that formats discount price payload
export const processDiscountPrice = (
    response: DrugDiscountPriceResponse,
    lookUpData: DrugWithDiscountPrice
): DrugWithDiscountPrice => {
    // Initialize values
    let priceValue = 'NA';
    let awpPriceValue = 'NA';

    // Ensure you get a valid price
    if (response.prescriptionResult[0].status !== 'DENIED') {
        // Get Price value
        priceValue = response.prescriptionResult[0]?.pricingInformation?.memberCost?.patientPayAmount
            ? response.prescriptionResult[0].pricingInformation.memberCost.patientPayAmount
            : priceValue; // '8.25';

        // Get awp price
        awpPriceValue = response.prescriptionResult[0]?.pricingInformation?.awpPrice
            ? response.prescriptionResult[0].pricingInformation.awpPrice
            : awpPriceValue; // '400.00';
    }

    return {
        price: priceValue,
        awpPrice: awpPriceValue,
        rxNumber: lookUpData.rxNumber as string,
        planAlias: response.member.memberIdent.clientAccountCode,
        isBirdiSelect: response.isBirdiSelect,
        zipCode: lookUpData.zipCode,
        drugCode: lookUpData.drugCode,
        quantity: lookUpData.quantity,
        daysSupply: lookUpData.daysSupply,
        gpi: lookUpData.gpi
    };
};
