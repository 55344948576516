import classNames from 'classnames';
import { PropsWithChildren, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import ArrowIcon from 'ui-kit/icons/arrow/arrow-icon';
import ModalCloseIcon from 'ui-kit/icons/modal-close-icon/modal-close-icon';

import { modalComponentSelector } from 'state/modal/modal.selector';

import useElementOnScreen from 'hooks/useElementOnScreen';

import { ModalCloseButtonProps, ModalComponentProps } from './modal.props';
import './modal.styles.scss';

/**
 * Base Modal component implementation
 * @returns Jsx.Element
 */
export default function ModalComponent() {
    const modalState = useSelector(modalComponentSelector);

    return <ModalComponentRender {...modalState} />;
}
/**
 * Used to maintain the default layout when the default modal configuration is not used
 * Ideal for use around content with customized forms and actions
 * @param props Children component
 * @returns Jsx.Element
 */
export const ModalComponentContent = (props: PropsWithChildren<unknown>) => {
    return <div className="modal-body-inner-content">{props.children}</div>;
};

/**
 * Used to maintain the default layout when the default modal configuration is not used
 * Maintains the same layout settings as Modal.footer, leaving the component standardized
 * new properties can be added to complement the component
 * @param props Partial ModalComponentProps
 * @returns Jsx.Element
 */
export const ModalComponentFooter = (
    props: Pick<
        ModalComponentProps,
        | 'cancelButtonLabel'
        | 'continueButtonLabel'
        | 'onCancel'
        | 'onContinue'
        | 'footerLayout'
        | 'isCTABusy'
        | 'isCTADisabled'
    >
) => {
    const modalFooterClass = classNames({ 'vertical-footer': props.footerLayout === 'vertical' });

    const handleCancel = () => {
        if (props.onCancel) props.onCancel();
    };

    const handleContinue = () => {
        if (props.onContinue) props.onContinue();
    };

    return (
        <Modal.Footer className={modalFooterClass}>
            <Button
                type="button"
                label={props.cancelButtonLabel as string}
                variant="link"
                onClick={handleCancel}
                disabled={props.isCTADisabled || props.isCTABusy}
            />
            <Button
                async={props.isCTABusy}
                type="button"
                label={props.continueButtonLabel as string}
                variant="primary"
                onClick={handleContinue}
                isBusy={props.isCTABusy}
                disabled={props.isCTADisabled || props.isCTABusy}
            />
        </Modal.Footer>
    );
};

export const ModalCloseButton = ({ onClose }: ModalCloseButtonProps) => {
    return (
        <div className="modal-component-close-icon">
            <button type="button" onClick={onClose}>
                <ModalCloseIcon />
            </button>
        </div>
    );
};

export const ModalComponentHeader = (
    props: Pick<
        ModalComponentProps,
        | 'hasDefaultTitle'
        | 'hasTitleNavigation'
        | 'onClose'
        | 'isCloseable'
        | 'title'
        | 'subtitle'
        | 'onNavigate'
        | 'modalStep'
        | 'variation'
    > & {
        classNames: string;
    }
) => {
    const modalHeaderClass = classNames({
        'only-close-icon': !props.hasDefaultTitle,
        'side-view-header': props.variation === 'side-view'
    });

    return (
        <Modal.Header className={`${modalHeaderClass} ${props.classNames}`} closeButton={false}>
            {props.hasDefaultTitle && (
                <Modal.Title>
                    {props.hasTitleNavigation && (
                        <div className="goBack-icon">
                            <button type="button" onClick={props.onNavigate}>
                                <ArrowIcon />
                            </button>
                        </div>
                    )}
                    {props.title}
                </Modal.Title>
            )}

            {props.subtitle && (
                <div className="modal-subtitle">
                    <p>{props.subtitle}</p>
                </div>
            )}

            {props.isCloseable && <ModalCloseButton onClose={props.onClose} />}
        </Modal.Header>
    );
};

export const ModalComponentRender = ({
    isCloseable = true,
    footerLayout = 'horizontal',
    continueButtonLabel = 'Save',
    cancelButtonLabel = 'Cancel',
    hasTitleNavigation = false,
    hasDefaultFooter = true,
    hasCustomContent = false,
    hasDefaultTitle = true,
    hasModalHeader = true,
    onClose,
    onNavigate,
    customDialogClassName,
    isLoading,
    ...props
}: ModalComponentProps) => {
    const isSideView = props.variation === 'side-view';

    const modalBodyClass = classNames({
        'with-custom-content': hasCustomContent,
        'side-view-body': isSideView
    });
    const modalDialogClass = classNames({ 'side-view-sizing': isSideView });

    const ref = useRef<HTMLHeadingElement>(null);
    const isTitleVisible = useElementOnScreen(ref);

    // DRX-4428: This imperative focus is needed to trigger the ref logic
    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [ref]);

    const shouldDisplayHeader = classNames({
        'show-header': isSideView && !isTitleVisible,
        'hide-header': isSideView && isTitleVisible
    });

    const getModalSize = (size: ModalComponentProps['variation']) => {
        if (customDialogClassName || size === 'side-view') return undefined;
        if (size === 'small') return 'sm';
        return 'lg';
    };

    const handleClose = () => {
        onClose();
    };

    const handleNavigate = () => {
        if (onNavigate) onNavigate();
    };

    return (
        <Modal
            restoreFocus={false}
            onEscapeKeyDown={handleClose}
            onBackdropClick={isCloseable ? handleClose : undefined}
            backdrop={isCloseable ? true : 'static'}
            size={getModalSize(props.variation)}
            centered={props.isCentered}
            show={props.show}
            data-ga-type="modal"
            dialogClassName={`custom-modal-component ${customDialogClassName} ${modalDialogClass}`}
            contentClassName="custom-modal-component-content"
            backdropClassName="custom-modal-component-backdrop"
            onHide={handleClose}
        >
            {/* Modal Title */}
            {hasModalHeader && (
                <ModalComponentHeader
                    {...props}
                    isCloseable={isCloseable}
                    title={props.title}
                    hasDefaultTitle={hasDefaultTitle}
                    onNavigate={handleNavigate}
                    onClose={handleClose}
                    classNames={shouldDisplayHeader}
                />
            )}
            {/* End Modal Title */}

            {/* Modal Content */}
            {props.content && (
                <Modal.Body className={modalBodyClass}>
                    {isSideView && (
                        <>
                            <div className="side-view-body__detail-container">{props.sideView && props.sideView}</div>
                            <div className="side-view-body__content-container">
                                <div className="side-view-body__inner-header">
                                    {props.badge}
                                    {isCloseable && <ModalCloseButton onClose={onClose} />}
                                </div>
                                <h1 className="side-view-body__content-container__title" ref={ref}>
                                    {props.title}
                                </h1>
                                {props.content}
                            </div>
                        </>
                    )}
                    {!isSideView && props.content}
                </Modal.Body>
            )}
            {/* End Modal Content */}

            {/* Modal Footer */}
            {hasDefaultFooter && (
                <ModalComponentFooter
                    {...props}
                    isCTABusy={isLoading}
                    continueButtonLabel={continueButtonLabel}
                    cancelButtonLabel={cancelButtonLabel}
                    footerLayout={footerLayout}
                />
            )}
            {/* End Modal Footer */}
        </Modal>
    );
};
