import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { createRoutine } from 'redux-saga-routines';

import {
    AddressesAutoRefillRequest,
    AddressesResponse,
    AuthenticateUserResponse,
    AutoRefillFlag,
    GetEasyAutoRefillPatientDataResponse,
    PaymentCardsAutoRefillRequest,
    PaymentCardsResponse,
    VerifyUserResponse
} from 'types/auto-refill';
import { RxDetails } from 'types/prescription';

import { BIRDI_PLANS } from 'enums/plans';

import { hasRxInFamily } from './auto-refill.helpers';

export interface AutoRefillState {
    addresses: AddressesResponse[];
    autoRefillFlags: AutoRefillFlag[];
    autoRefill: boolean;
    bearerToken: string;
    emailSent: boolean;
    epostPatientNum: string;
    epostPlanNum: string;
    error: {
        messageText?: string;
    };
    familyDependents: GetEasyAutoRefillPatientDataResponse[];
    firstName: string;
    isBusy: boolean;
    isCaregiver: boolean;
    isFamilyMembersWithRx: boolean;
    isPlanAutoRefillOn: boolean;
    lastName: string;
    loadingAdresses: boolean;
    loadingPaymentCards: boolean;
    loadingPrescriptions: boolean;
    orderInvoiceNumber: string;
    patientAutoRefill: boolean;
    isLoadingPatientData?: boolean;
    paymentCards: PaymentCardsResponse[];
    planAlias: BIRDI_PLANS;
    prescriptionsLoaded: boolean;
    rxResults: RxDetails[];
    secretKeyHash: string;
    securityToken: string;
    userVerified: 'UserNotVerified' | 'UserVerified' | 'MaxAttemptsReached' | 'TokenValid' | 'TokenExpired' | '';
    activeTab: string | null;
}

const initialState: AutoRefillState = {
    addresses: [],
    autoRefillFlags: [],
    autoRefill: false,
    bearerToken: '',
    emailSent: false,
    epostPatientNum: '',
    epostPlanNum: '',
    error: {
        messageText: undefined
    },
    familyDependents: [],
    firstName: '',
    isBusy: false,
    lastName: '',
    loadingAdresses: false,
    loadingPaymentCards: false,
    loadingPrescriptions: false,
    isCaregiver: false,
    isFamilyMembersWithRx: false,
    isPlanAutoRefillOn: false,
    orderInvoiceNumber: '',
    patientAutoRefill: false,
    paymentCards: [],
    planAlias: '',
    prescriptionsLoaded: false,
    rxResults: [],
    secretKeyHash: '',
    securityToken: '',
    userVerified: '',
    activeTab: ''
};

// Routines
export const autoRefillVerifyUserRoutine = createRoutine('auto-refill/VERIFY_USER');
export const autoRefillGetSecretKeyHashRoutine = createRoutine('auto-refill/GET_SECRET_KEY_HASH');
export const autoRefillAuthenticateUserRoutine = createRoutine('auto-refill/AUTHENTICATE_USER');
export const autoRefillGetPatientDataRoutine = createRoutine('auto-refill/GET_PATIENT_DATA');
export const autoRefillToggleAutoRefillRxStatusRoutine = createRoutine('auto-refill/TOGGLE_AUTO_REFILL_RX_STATUS');
export const autoRefillGetPatientAdressesRoutine = createRoutine('auto-refill/GET_PATIENT_ADRESSES');
export const autoRefillGetPatientPaymentCardsRoutine = createRoutine('auto-refill/GET_PATIENT_PAYMENT_CARDS');

const autoRefillSlice = createSlice({
    name: 'auto-refill',
    initialState,
    reducers: {
        setSecurityToken: (state, action: PayloadAction<{ securityToken: string }>) => {
            state.securityToken = action.payload.securityToken;
        },
        resetBearerToken: (state) => {
            state.bearerToken = initialState.bearerToken;
        },
        setActiveTab: (state, { payload }: PayloadAction<string | null>) => {
            state.activeTab = payload;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Auto Refill reducers
         */
        // Verify User
        addCase(autoRefillVerifyUserRoutine.SUCCESS, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.userVerified = payload.verificationStatus;
                draftState.epostPatientNum = payload.epostPatientNum;
            })
        );
        addCase(autoRefillVerifyUserRoutine.FAILURE, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload.messageText;
            })
        );
        // Getting user secret key hash for authentication
        addCase(autoRefillGetSecretKeyHashRoutine.SUCCESS, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.secretKeyHash = payload;
            })
        );
        addCase(autoRefillGetSecretKeyHashRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload;
            })
        );
        // authenticate user
        addCase(
            autoRefillAuthenticateUserRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = payload.bearerToken;
                    draftState.isCaregiver = payload.isCaregiver;
                })
        );
        addCase(
            autoRefillAuthenticateUserRoutine.FAILURE,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = initialState.bearerToken;
                    draftState.error.messageText = payload.messageText;
                })
        );
        // Fetching Patient data
        addCase(
            autoRefillGetPatientDataRoutine.TRIGGER,
            (state, { payload }: PayloadAction<{ idLoadingPrescriptions: boolean }>) =>
                produce(state, (draftState) => {
                    draftState.loadingPrescriptions = payload.idLoadingPrescriptions ?? true;
                    draftState.isBusy = true;
                    draftState.isLoadingPatientData = true;
                })
        );

        addCase(
            autoRefillGetPatientDataRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ data: GetEasyAutoRefillPatientDataResponse[] }>) =>
                produce(state, (draftState) => {
                    draftState.loadingPrescriptions = false;
                    draftState.prescriptionsLoaded = true;
                    draftState.isBusy = false;
                    draftState.isLoadingPatientData = false;

                    const { data } = payload;

                    const caregiver =
                        data.length > 0
                            ? data[0]
                            : data.find((patient) => patient.epostPatientNum === draftState.epostPatientNum);

                    draftState.firstName = caregiver?.firstName ?? draftState.firstName;
                    draftState.isPlanAutoRefillOn = caregiver?.autoRefill ?? draftState.isPlanAutoRefillOn;
                    draftState.planAlias = caregiver?.planAlias || '';
                    draftState.patientAutoRefill = caregiver?.patientAutoRefill || false;
                    draftState.rxResults = caregiver?.rxResults || [];
                    draftState.isFamilyMembersWithRx = hasRxInFamily(data);
                    draftState.familyDependents = data;
                    draftState.activeTab = state.epostPatientNum;
                })
        );
        addCase(autoRefillGetPatientDataRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPrescriptions = false;
                draftState.prescriptionsLoaded = true;
                draftState.rxResults = initialState.rxResults;
                draftState.autoRefillFlags = initialState.autoRefillFlags;
                draftState.familyDependents = initialState.familyDependents;
                draftState.isBusy = false;
                draftState.isLoadingPatientData = false;
            })
        );
        addCase(autoRefillToggleAutoRefillRxStatusRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(
            autoRefillToggleAutoRefillRxStatusRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ rxNumber: string; autoRefillEnabled: boolean }>) =>
                produce(state, (draftState) => {
                    const autoRefillFlags = draftState.autoRefillFlags;
                    const rxResults = draftState.rxResults;
                    const { rxNumber } = payload;

                    const rxIndex = autoRefillFlags?.findIndex((rx) => rx.rxNumber === payload.rxNumber);
                    draftState.autoRefillFlags = autoRefillFlags?.map((autoRefillFlag, index) => {
                        if (index !== rxIndex) {
                            return autoRefillFlag;
                        }

                        return { ...autoRefillFlag, autoRefillEnabled: payload.autoRefillEnabled };
                    });
                    draftState.rxResults = rxResults?.map((rx, index) => {
                        if (rx.rxNumber !== rxNumber) {
                            return rx;
                        }

                        return { ...rx, consentExpiration: '' };
                    });
                    draftState.isBusy = false;
                })
        );
        addCase(autoRefillToggleAutoRefillRxStatusRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );

        addCase(autoRefillGetPatientAdressesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAdresses = true;
            })
        );
        addCase(
            autoRefillGetPatientAdressesRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AddressesAutoRefillRequest>) =>
                produce(state, (draftState) => {
                    draftState.loadingAdresses = false;
                    draftState.addresses = payload.addresses;
                })
        );
        addCase(autoRefillGetPatientAdressesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAdresses = false;
                draftState.addresses = initialState.addresses;
            })
        );

        addCase(autoRefillGetPatientPaymentCardsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPaymentCards = true;
            })
        );
        addCase(
            autoRefillGetPatientPaymentCardsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<PaymentCardsAutoRefillRequest>) =>
                produce(state, (draftState) => {
                    draftState.loadingPaymentCards = false;
                    draftState.paymentCards = payload.paymentCards;
                })
        );
        addCase(autoRefillGetPatientPaymentCardsRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPaymentCards = false;
                draftState.paymentCards = initialState.paymentCards;
            })
        );
    }
});

export const autoRefillActions = autoRefillSlice.actions;

export default autoRefillSlice.reducer;
