import { graphql, navigate } from 'gatsby';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import InfoBox from 'ui-kit/info-box/info-box';
import ToastBox from 'ui-kit/toast-box/toast-box';

import LearnMoreContentModal from 'display-components/auto-refill-modal-contents/learn-more';
import TermsAndConditionsContent from 'display-components/auto-refill-modal-contents/terms-and-conditions';

import { CartReviewSectionHeader } from 'components/cart-review-section-header';
import CartQuickLinks from 'components/cart/cart-quick-links/cart-quick-links';
import { CartTotal } from 'components/cart/cart-total/cart-total.component';
import { PrescriptionInformation } from 'components/cart/order-confirmation/prescription-information.component';
import CartLayout from 'components/layouts/cart/cart.layout';

import {
    accountAutoRefillPlanEligibleSelector,
    accountHasInsuranceSelector,
    accountIsCaliforniaUserSelector,
    accountIsMembershipSelector,
    accountPlansSelector,
    accountProfilIsCaregiverSelector
} from 'state/account/account.selectors';
import { resetCart } from 'state/cart/cart.reducers';
import { getCartRoutine } from 'state/cart/cart.routines';
import {
    cartOrderBillShipMethodSelector,
    cartOrderBirdiOrderNumberSelector,
    cartOrderHeaderSelector,
    cartOrderPaymentCardSelector,
    cartOrderPlacedSelector,
    cartOrderShippingAddressSelector,
    cartSelector,
    cartSubtotalSelector
} from 'state/cart/cart.selectors';
import { drugsWithDiscountSelector } from 'state/drug/drug.selectors';
import {
    medicineCabinetGetAllPrescriptions,
    medicineCabinetToggleAutoRefillForRx
} from 'state/medicine-cabinet/medicine-cabinet.routines';
import {
    medicineCabinetActiveTabSelector,
    medicineCabinetAutoRefillPlanEligibleSelector,
    medicineCabinetPrescriptionsSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import { CartPayload, ExtendedCartObjectPayload } from 'types/cart';
import { RefillRxs } from 'types/order-prescription';
import { PrescriptionObjectPayload, RxDetails } from 'types/prescription';

import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID, processCart } from 'util/cart';
import { replaceStringWith } from 'util/string';

import usePopState from 'hooks/usePopState';

import './index.style.scss';

// Helper function to update the cart with the Auto Refill flag set as true.
const getUpdatedRxAutoRefillOn = (
    cartItems: ExtendedCartObjectPayload[],
    rxSeqNumToUpdate: string[],
    isCaregiver?: boolean
): ExtendedCartObjectPayload[] => {
    const rxList = isCaregiver ? cartItems.flatMap((rx) => rx.extendedRefillRxs) : cartItems[0].extendedRefillRxs;

    const updatedCartItems = rxList.map((rx) => {
        if (rxSeqNumToUpdate.includes(rx.prescriptionDetail.rxNumber)) {
            return {
                ...rx,
                prescriptionDetail: { ...rx.prescriptionDetail, autoFillStatus: 'Y', autoRefillEnabled: true }
            };
        } else {
            return rx;
        }
    });

    return [{ ...cartItems[0], extendedRefillRxs: updatedCartItems }] as ExtendedCartObjectPayload[];
};

const OrderConfirmation = ({ data }: { data: GatsbyTypes.CartOrderConfirmationDataQuery }) => {
    const { blueSkyBackground } = data;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // state
    const [hasUnknownPrice, setHasUnknownPrice] = useState<boolean>(false);
    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedCartObjectPayload[]>([]);
    const [autoRefillEnabled, setAutoRefillEnabled] = useState<string[]>([]);

    // selectors
    const orderHeader = useSelector(cartOrderHeaderSelector);
    const birdiOrderNumber = useSelector(cartOrderBirdiOrderNumberSelector);
    const shippingAddress = useSelector(cartOrderShippingAddressSelector);
    const paymentCard = useSelector(cartOrderPaymentCardSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const cartObject = useSelector(cartSelector);
    const activeDependentTab = useSelector(medicineCabinetActiveTabSelector);
    const prescriptionsObject = useSelector(medicineCabinetPrescriptionsSelector);
    const drugDiscountPrices = useSelector(drugsWithDiscountSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const shipMethodId = useSelector(cartOrderBillShipMethodSelector);
    const displayShowToggleAutoRefillAll = useSelector(medicineCabinetAutoRefillPlanEligibleSelector);
    const isOrderPlaced = useSelector(cartOrderPlacedSelector);
    const cartSubtotal = useSelector(cartSubtotalSelector);
    const isCaregiver = useSelector(accountProfilIsCaregiverSelector);
    const isMembership = useSelector(accountIsMembershipSelector);
    const isPlanAutoRefill = useSelector(accountAutoRefillPlanEligibleSelector);
    const isCAResident = useSelector(accountIsCaliforniaUserSelector);

    const epostNumFamilyMember =
        activeDependentTab && activeDependentTab !== '' ? { epostNumFamilyMember: activeDependentTab } : {};

    const prescriptionsInCart = (cart: CartPayload[]) => {
        const prescriptionsInCart: RxDetails[] = [];

        cart.forEach((orderPayload: CartPayload) => {
            if (orderPayload.Order.refillRxs && orderPayload.Order.refillRxs.length > 0) {
                orderPayload.Order.refillRxs.forEach((refillRx: RefillRxs) => {
                    if (refillRx.prescriptionDetail) {
                        prescriptionsInCart.push(refillRx.prescriptionDetail);
                    }
                });
            }
        });

        return prescriptionsInCart;
    };

    const getCartOrder = useCallback(async () => {
        if (cartObject) {
            const extendedCart = processCart(
                cartObject,
                accountHasInsurance,
                isCaregiver ? await prescriptionsInCart(cartObject) : prescriptionsObject,
                drugDiscountPrices,
                accountPlans,
                isMembership,
                shippingAddress?.zipcode || ''
            );
            setExtendedCartObject(getUpdatedRxAutoRefillOn(extendedCart, autoRefillEnabled, isCaregiver));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartObject, accountHasInsurance, prescriptionsObject, drugDiscountPrices, accountPlans]);

    // We implement this hook here, to avoid the user to gets back to the review order page, when the order has been placed
    usePopState(() => {
        dispatch(resetCart());
    });

    // Prevent to show this page if the order is not sent yet
    useEffect(() => {
        if (!isOrderPlaced) {
            navigate('/secure/medicine-cabinet');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // reload the cart after left the order-confirmation page
    useEffect(
        () => () => {
            dispatch(getCartRoutine.trigger());
            dispatch(medicineCabinetGetAllPrescriptions.trigger({ ...epostNumFamilyMember }));
        },
        [dispatch]
    );

    useEffect(() => {
        // Trigger getCartOrder only if the extendedCartObject is empty
        if (extendedCartObject.length === 0) {
            getCartOrder();
        }
    }, [getCartOrder, extendedCartObject]);

    useEffect(() => {
        setHasUnknownPrice(extendedCartObject?.some((item) => item.itemHasUnknownPrice) ?? false);
    }, [extendedCartObject]);

    const handleAutoRefillToggle = useCallback(
        (rxNumber: string, autoRefillEnabled: boolean) => {
            setAutoRefillEnabled([rxNumber]);
            dispatch(
                medicineCabinetToggleAutoRefillForRx.trigger({
                    rxNumber: rxNumber,
                    autoRefillEnabled: autoRefillEnabled,
                    onSuccess: () => {
                        dispatch(closeModalComponent());
                        setExtendedCartObject(getUpdatedRxAutoRefillOn(extendedCartObject, [rxNumber], isCaregiver));
                    },
                    onFailure: () => {
                        dispatch(closeModalComponent());
                    }
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, extendedCartObject]
    );

    const handleAutoRefillInCard = useCallback(
        (prescription: PrescriptionObjectPayload, value: boolean) => {
            if (!prescription.autoRefillEnabled) {
                handleShowTCModal(prescription.rxNumber, prescription.rxSeqNum, value);
            } else {
                handleAutoRefillToggle(prescription.rxNumber, value);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleAutoRefillToggle]
    );

    const handleCloseModal = () => {
        dispatch(closeModalComponent());
    };

    const handleShowTCModal = useCallback(
        (rxNumber: string, rxSeqNum: string, autoRefillEnabled: boolean) => {
            dispatch(
                openModalComponent({
                    title: t('pages.profile.autoRefill.autoRefillTC.modalTitle'),
                    isCloseable: true,
                    customDialogClassName: 'learn-more-modal-header',
                    hasModalHeader: true,
                    variation: 'small',
                    isCentered: true,
                    hasCustomContent: false,
                    continueButtonLabel: t('button.confirm'),
                    content: <TermsAndConditionsContent />,
                    onContinue: () => handleAutoRefillToggle(rxNumber, autoRefillEnabled),
                    onClose: handleCloseModal
                })
            );
        },
        [dispatch, handleAutoRefillToggle, t]
    );

    const handleAutoRefillModal = useCallback(() => {
        dispatch(
            openModalComponent({
                title: t('pages.profile.autoRefill.learnMore.label'),
                isCloseable: true,
                customDialogClassName: 'learn-more-modal-header',
                hasDefaultFooter: false,
                hasModalHeader: true,
                variation: 'small',
                isCentered: true,
                hasCustomContent: false,
                content: <LearnMoreContentModal />,
                onClose: handleCloseModal
            })
        );
    }, [dispatch, t]);

    return (
        <CartLayout
            backgroundImage={blueSkyBackground}
            nodeTitle={t('pages.cartOrderConfirmation.title')}
            title={t('pages.cartOrderConfirmation.headlineText')}
            eyebrowText={t('pages.cartOrderConfirmation.eyebrowText')}
            variant="confirmation"
        >
            <>
                <Container fluid>
                    <Row className="cart-order-confirmation-order-message">
                        <Col xs={12} md={12} lg={12}>
                            <h4
                                dangerouslySetInnerHTML={{
                                    __html: t('pages.cartOrderConfirmation.orderMessage', {
                                        orderNumber: isCaregiver ? birdiOrderNumber : orderHeader?.orderInvoiceNumber
                                    })
                                }}
                            ></h4>
                        </Col>
                    </Row>
                </Container>
                <CartReviewSectionHeader
                    label={t('pages.cart.prescriptionInformation')}
                    pricingLabel={t('pages.cart.pricing')}
                />

                <PrescriptionInformation
                    accountHasInsurance={accountHasInsurance}
                    t={t}
                    fullCart={extendedCartObject || []}
                    isCaregiver={isCaregiver}
                    onPrescriptionAutoRefillChange={handleAutoRefillInCard}
                    isMembership={isMembership}
                />
                <CartTotal
                    t={t}
                    hasUnknownPrice={hasUnknownPrice}
                    initialOrderPrice={String(cartSubtotal)}
                    currentShippingPrice={shipMethodId === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0}
                    disclaimerText={t('pages.cart.totalDisclaimerOrderConfirmation')}
                    isInsuranceAccount={accountHasInsurance}
                />
                <CartReviewSectionHeader label={t('pages.cartOrderConfirmation.sectionHeaders.shippingInformation')} />
                <Container fluid>
                    <Row>
                        <Col className="cart-order-confirmation-section-content">
                            <div className="cart-order-confirmation-default-address-label">
                                <h6>{t('pages.cartOrderConfirmation.shippingInformation.defaultAddressLabel')}</h6>
                            </div>
                            <div className="cart-order-confirmation-default-address">
                                {shippingAddress && (
                                    <>
                                        <span className="cart-order-confirmation-section-sentence">
                                            {shippingAddress.address1}{' '}
                                            {shippingAddress.address2 ? shippingAddress.address2 : ''}
                                        </span>
                                        <span className="cart-order-confirmation-section-sentence">
                                            {shippingAddress.city}, {shippingAddress.state}{' '}
                                            {shippingAddress.zipcodeFour
                                                ? `${shippingAddress.zipcode}-${shippingAddress.zipcodeFour}`
                                                : shippingAddress.zipcode}
                                        </span>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <CartReviewSectionHeader label={t('pages.cartOrderConfirmation.sectionHeaders.shippingMethod')} />
                <Container fluid>
                    <Row>
                        <Col className="cart-order-confirmation__shipping-method">
                            <p>
                                {shipMethodId === DEFAULT_SHIPPING_ID
                                    ? t('pages.cart.freeShipping')
                                    : t('pages.cart.expeditedShipping')}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ToastBox>{t('pages.cart.shippingFooter')}</ToastBox>
                        </Col>
                    </Row>
                </Container>
                <CartReviewSectionHeader label={t('pages.cartOrderConfirmation.sectionHeaders.paymentInformation')} />
                <Container fluid>
                    <Row>
                        <Col className="cart-order-confirmation-section-content">
                            <div className="cart-order-confirmation-default-payment-method-label">
                                <h6>{t('pages.cartOrderConfirmation.paymentInformation.primaryPaymentMethod')}</h6>
                            </div>
                            <div className="cart-order-confirmation-default-payment-method">
                                {paymentCard && (
                                    <>
                                        <span className="cart-order-confirmation-card-info">
                                            {t('pages.cartOrderConfirmation.paymentInformation.cardEnding', {
                                                cardType: paymentCard.cardType,
                                                endingDigits: replaceStringWith(
                                                    paymentCard.secureCardNumber,
                                                    new RegExp(/\*/g),
                                                    ''
                                                )
                                            })}
                                            <br />
                                            {paymentCard.cardName}
                                            <br />
                                            Exp. {paymentCard.cardExpiration}
                                        </span>
                                        <CreditCardIcon
                                            className="payment-card__credit-card-icon"
                                            variant={paymentCard.cardType}
                                        />
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
                {ENABLE_AUTO_REFILL && displayShowToggleAutoRefillAll && isPlanAutoRefill && !isCAResident && (
                    <InfoBox
                        button={
                            <Button
                                onClick={handleAutoRefillModal}
                                className="p-0 btn-demi d-none d-lg-block text-cerulean"
                                variant="text"
                                label={t('pages.medicineCabinet.autoRefillLearnMoreLink')}
                                type="button"
                            />
                        }
                    >
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(t('pages.cartOrderConfirmation.turnOnAutoRefill'), {
                                    allowedTags: ['strong']
                                })
                            }}
                        ></p>
                    </InfoBox>
                )}
                <CartReviewSectionHeader label={t('pages.prescriptionConfirmation.sectionHeaders.quickLinks')} />
                <CartQuickLinks />
            </>
        </CartLayout>
    );
};

export default OrderConfirmation;

export const query = graphql`
    query CartOrderConfirmationData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
            nodes {
                field_physical_address
                field_mailing_address
                field_specialty_mailing_address
                field_specialty_physical_address
            }
        }
    }
`;
