// General
import { graphql, PageProps } from 'gatsby';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import PageSection from 'ui-kit/page-section/page-section';

import LearnMoreContentModal from 'display-components/auto-refill-modal-contents/learn-more';
import NewRxModal from 'display-components/modals/new-rx';
// Display components
import QuickLinks from 'display-components/quick-links';
import { CurrentFlow } from 'display-components/quick-links/quick-links.props';

// Components
import AccountBalance from 'components/account-balance/account-balance.component';
import AutoRefillModalContent from 'components/auto-refill-modal';
import { AutoRefillImage } from 'components/auto-refill-modal/auto-refill-modal';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import MedicineCabinetLayout from 'components/layouts/medicine-cabinet/medicine-cabinet.layout';
import MedicineCabinetCart from 'components/medicine-cabinet-cart/medicine-cabinet-cart';
import PrescriptionsList from 'components/prescriptions-list/prescriptions-list.component';
import 'components/prescriptions-list/prescriptions-list.style.scss';

import { accountFetchProfileRoutine, accountUpdateAutoRefill } from 'state/account/account.routines';
// State
import {
    accountAcknowledgementTermsStatusSelector,
    accountAutoRefillEligibleSelector,
    accountAutoRefillPlanEligibleSelector,
    accountAutoRefillSelector,
    accountIsCaliforniaUserSelector,
    accountIsMembershipSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
// Utils
import {
    medicineCabinetPopulatedSelector,
    medicineShowNewPrescriptionModalSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import { validateAutoRefillModalDisplay } from 'util/autorefill';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackNewPrescriptionNumber } from 'util/google_optimize/optimize_helper';
import storageHelper from 'util/storageHelper';

type MedicineCabinetProps = PageProps<GatsbyTypes.MedicineCabinetDataQuery>;

// Main component
const MedicineCabinet: React.FC<MedicineCabinetProps> = ({ data }) => {
    //General
    const pillImage = data.successModalPillImage;

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Selectors
    const newPrescriptionModal = useSelector(medicineShowNewPrescriptionModalSelector);
    const profileObject = useSelector(accountProfileSelector);
    const hasAcceptedTerms = useSelector(accountAcknowledgementTermsStatusSelector);
    const isCAResident = useSelector(accountIsCaliforniaUserSelector);
    const isMedicineCabinetLoaded = useSelector(medicineCabinetPopulatedSelector);
    const isAutoRefillEligible = useSelector(accountAutoRefillEligibleSelector);
    const isAccountAutorefillOn = useSelector(accountAutoRefillSelector);
    const planAllowsAutoRefill = useSelector(accountAutoRefillPlanEligibleSelector);
    const isMembership = useSelector(accountIsMembershipSelector);

    // States
    const [hasNewRxModalOpened, setHasNewRxModalOpened] = useState<boolean>(false);

    // Constants
    const userName = profileObject ? profileObject.patientFirstName : '';

    const shouldDisplayLearnMoreAutorefillModal = useMemo(() => {
        return (!ENABLE_AUTO_REFILL || !(isAccountAutorefillOn || isCAResident)) && planAllowsAutoRefill;
    }, [isAccountAutorefillOn, isCAResident, planAllowsAutoRefill]);

    const closeModalHandler = useCallback(() => {
        dispatch(closeModalComponent());
        storageHelper.local.setAutoRefillToggleFlag();
    }, [dispatch]);

    const handleToggleAutoRefill = () => {
        dispatch(
            accountUpdateAutoRefill.trigger({
                epostPatientNumber: profileObject?.epostPatientNum,
                autoRefillFlag: !profileObject?.autoRefill,
                onSuccess: () => {
                    closeModalHandler();
                    dispatch(accountFetchProfileRoutine.trigger());

                    // only display success modal for toggling OFF auto refill
                    if (!profileObject?.autoRefill) {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        title={t('pages.profile.autoRefill.successModal')}
                                        body={
                                            <div className="success-content-container">
                                                <p className="success-content-container-subtitle">
                                                    {t('pages.profile.autoRefill.successToggleTitle')}
                                                </p>
                                            </div>
                                        }
                                        icon={'default'}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('pages.profile.autoRefill.successDoneBtn'),
                                        variant: 'primary',
                                        className: 'auto-refill-success-btn',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'AutoRefillToggleModalSuccess'
                                    }
                                ]
                            })
                        );
                    }
                },
                onFailure: (error: any) => {
                    // DRX-4428: Only closes the modal without setting any local flag, since it is a failure scenario
                    dispatch(closeModalComponent());

                    dispatch(
                        openModal({
                            showClose: false,
                            type: 'danger',
                            size: 'lg',
                            onClose: () => dispatch(closeModal({})),
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('components.membershipModals.errorTitle')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: (
                                <BirdiModalContentAlt
                                    subTitle={t(`pages.medicineCabinet.messages.callbacks.autoRefillErrorMessage`)}
                                    note={t(`pages.medicineCabinet.messages.callbacks.errorMessageNote`, {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })}
                                />
                            ),
                            ctas: [
                                {
                                    label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const learnMoreAutorefillModal = useCallback(() => {
        dispatch(
            openModalComponent({
                title: t('pages.profile.autoRefill.learnMore.label'),
                isCloseable: true,
                customDialogClassName: 'learn-more-modal-header',
                hasDefaultFooter: false,
                hasModalHeader: true,
                variation: 'small',
                isCentered: true,
                hasCustomContent: false,
                content: <LearnMoreContentModal />,
                onClose: closeModalHandler
            })
        );
    }, [dispatch, t]);

    const openNewRxModal = useCallback(() => {
        if (!hasNewRxModalOpened && newPrescriptionModal.prescriptions.length > 0 && !isMembership) {
            dispatch(
                openModal({
                    showClose: true,
                    className: 'prescription-modal',
                    bodyContent: <NewRxModal prescriptions={newPrescriptionModal.prescriptions} />,
                    onClose: () => {
                        dispatch(closeModal({}));
                    },
                    ctas: [
                        {
                            label: t('modals.newPrescriptions.labels.viewMedicineCabinet'),
                            variant: 'primary',
                            onClick: (event) => {
                                dispatch(closeModal({}));

                                const eventTarget = event?.target as HTMLElement;
                                if (newPrescriptionModal.prescriptions.length > 0 && eventTarget) {
                                    TrackNewPrescriptionNumber(eventTarget, newPrescriptionModal.prescriptions.length);
                                }
                            },
                            dataGALocation: t('modals.newPrescriptions.title.singlePrescription').replace(/ /g, '')
                        }
                    ]
                })
            );
            setHasNewRxModalOpened(true);
        }
    }, [dispatch, t, newPrescriptionModal, hasNewRxModalOpened, isMembership]);

    const showAutoRefillModal = useCallback(() => {
        if (validateAutoRefillModalDisplay(isAutoRefillEligible, isCAResident)) {
            dispatch(
                openModalComponent({
                    title: t('pages.autoRefill.userVerification.title'),
                    isCloseable: true,
                    hasDefaultFooter: false,
                    hasModalHeader: true,
                    variation: 'side-view',
                    isCentered: true,
                    hasCustomContent: false,
                    sideView: <AutoRefillImage />,
                    content: (
                        <AutoRefillModalContent
                            onClose={() => {
                                closeModalHandler();
                                openNewRxModal();
                            }}
                            onContinue={handleToggleAutoRefill}
                        />
                    ),
                    onClose: () => {
                        closeModalHandler();
                        openNewRxModal();
                    }
                })
            );
        } else {
            openNewRxModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAutoRefillEligible, isCAResident, openNewRxModal]);

    useEffect(() => {
        if (hasAcceptedTerms && isMedicineCabinetLoaded) {
            showAutoRefillModal();
        }
    }, [isMedicineCabinetLoaded, hasAcceptedTerms, profileObject, showAutoRefillModal, newPrescriptionModal]);

    return (
        <MedicineCabinetLayout greetingMessage={`${t('pages.medicineCabinet.greetingMessageDefault')} ${userName}`}>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={8}>
                        <div className="medicine-cabinet-v2-account-balance-banner">
                            <AccountBalance variant="banner" />
                        </div>
                        <PrescriptionsList pillImage={pillImage} />
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        <MedicineCabinetCart />
                    </Col>
                </Row>
            </PageSection>
            {isMedicineCabinetLoaded && (
                <QuickLinks
                    currentFlow={CurrentFlow.MEDICINE_CABINET}
                    displayAutorefillBanner={shouldDisplayLearnMoreAutorefillModal}
                    autorefillModal={learnMoreAutorefillModal}
                />
            )}
        </MedicineCabinetLayout>
    );
};

export default MedicineCabinet;

export const query = graphql`
    query MedicineCabinetData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        successModalPillImage: file(relativePath: { eq: "assets/images/hero-pill-image-sample.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
