import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { WritableDraft } from 'immer/dist/internal';

import { BIRDI_PLANS } from 'enums/plans';

import { findDrugInList } from 'util/drug';

import {
    drugDescriptionRoutine,
    drugDetailsLookupRoutine,
    drugDiscountPriceApiRoutine,
    drugDiscountPriceRoutine,
    drugFormLookupRoutine,
    drugListRoutine,
    drugLookupRoutine
} from './drug.routines';
import {
    DrugDescriptionObjectPayload,
    DrugDetailsObjectPayload,
    DrugListObjectPayload,
    DrugLookupObjectPayload
} from './drug.services';

type LookupStatus = 'BUSY' | 'FETCHED_DRUGS' | 'FETCHED_DETAILS' | 'FETCHED_PHOTO' | 'IDLE' | 'FAILED';

export interface DrugWithDiscountPrice {
    price?: string;
    awpPrice?: string;
    rxNumber?: string;
    planAlias?: BIRDI_PLANS;
    isBirdiSelect?: boolean;
    zipCode?: string;
    drugCode?: string;
    quantity?: string;
    daysSupply?: string;
    gpi?: string;
    isLoading?: boolean;
    location?: string;
}

export interface DrugState {
    drugLookupResults?: DrugLookupObjectPayload[];
    drugDetails: DrugDetailsObjectPayload;
    showDrugList: boolean;
    drugLookupStatus: LookupStatus;
    drugDiscountPrices: DrugWithDiscountPrice[];
    drugDescription: string;
    formularies?: DrugListObjectPayload[];
    isLoading?: boolean;
}

export const initialState: DrugState = {
    drugLookupResults: [],
    drugDetails: { drugName: '', dosageForms: [] },
    showDrugList: false,
    drugLookupStatus: 'IDLE',
    drugDiscountPrices: [],
    drugDescription: '',
    formularies: []
};

const updateDrugPrice = (
    state: WritableDraft<DrugState>,
    { payload }: PayloadAction<{ result: DrugWithDiscountPrice; cached?: boolean } | undefined>
) => {
    return produce(state, (draftState) => {
        if (!payload) return;
        const response: DrugWithDiscountPrice = { ...payload.result };
        // Check if the price already exists for the given prescription number.
        const existingIndex = draftState.drugDiscountPrices.findIndex((item) => {
            return (
                item.drugCode === response.drugCode &&
                item.zipCode === response.zipCode &&
                item.planAlias === response.planAlias
            );
        });

        // Update the existing price or add the new price to the array.
        if (existingIndex > -1) {
            // Only update the price if one was actually returned by the API.
            if (response.price && response.price !== 'NA') {
                draftState.drugDiscountPrices[existingIndex].price = response.price;
                draftState.drugDiscountPrices[existingIndex].isLoading = !payload.cached
                    ? false
                    : draftState.drugDiscountPrices[existingIndex].isLoading;
            }
        } else {
            draftState.drugDiscountPrices.push(response);
        }
    });
};

const drugSlice = createSlice({
    name: 'drug',
    initialState,
    reducers: {
        setDrugListVisibility(state, action: PayloadAction<boolean>) {
            state.showDrugList = action.payload;
        },
        setDrugLookupStatus(state, action: PayloadAction<LookupStatus>) {
            state.drugLookupStatus = action.payload;
        },
        resetDrugLookupResults(state) {
            state.drugLookupResults = initialState.drugLookupResults;
        },
        resetDrugDescription(state) {
            state.drugDescription = initialState.drugDescription;
        },
        resetDrugFormulary(state) {
            state.formularies = initialState.formularies;
        },
        setLoadingDrugPrices(state, action: PayloadAction<DrugWithDiscountPrice[]>) {
            const newDrugs: DrugWithDiscountPrice[] = [...action.payload].map((item: DrugWithDiscountPrice) => ({
                ...item,
                isLoading: true
            }));
            const drugHistory = [...state.drugDiscountPrices];
            const drugsToAddToHistory: DrugWithDiscountPrice[] = newDrugs.filter(
                (item) => !findDrugInList(item, drugHistory)
            );
            state.drugDiscountPrices = [...drugHistory, ...drugsToAddToHistory];
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Drug reducers
         */
        addCase(drugListRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugListObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.formularies?.push(payload);
                draftState.showDrugList = true;
                draftState.drugLookupStatus = 'FETCHED_DRUGS';
            })
        );
        addCase(drugListRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.formularies = initialState.formularies;
                draftState.showDrugList = false;
                draftState.drugLookupStatus = 'IDLE';
            })
        );
        addCase(drugLookupRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugLookupObjectPayload[]>) =>
            produce(state, (draftState) => {
                draftState.drugLookupResults = payload;
                draftState.showDrugList = true;
                draftState.drugLookupStatus = 'FETCHED_DRUGS';
            })
        );
        addCase(drugLookupRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.drugLookupResults = [];
                draftState.showDrugList = false;
            })
        );
        addCase(drugDetailsLookupRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugDetailsObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.drugDetails = payload;
                draftState.drugLookupStatus = 'FETCHED_DETAILS';
            })
        );
        addCase(drugDetailsLookupRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.drugDetails = { drugName: '', dosageForms: [] };
                draftState.drugLookupStatus = 'IDLE';
            })
        );
        addCase(drugFormLookupRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugDetailsObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.drugDetails = payload;
                draftState.drugLookupStatus = 'FETCHED_DETAILS';
            })
        );
        addCase(drugFormLookupRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.drugDetails = { drugName: '', dosageForms: [] };
                draftState.drugLookupStatus = 'IDLE';
            })
        );
        addCase(drugDiscountPriceRoutine.SUCCESS, updateDrugPrice);
        addCase(drugDiscountPriceApiRoutine.SUCCESS, updateDrugPrice);
        addCase(drugDiscountPriceApiRoutine.FAILURE, (state, { payload }: PayloadAction<string>) => {
            return produce(state, (draftState) => {
                // Set the price to "NA" for this prescription number.
                draftState.drugDiscountPrices.push({
                    price: 'NA',
                    awpPrice: 'NA',
                    rxNumber: payload,
                    planAlias: undefined
                });
            });
        });
        // BEFORE REQUEST
        addCase(drugDescriptionRoutine.REQUEST, (state) =>
            produce(state, (draftState) => {
                draftState.isLoading = true;
                draftState.drugDescription = '';
            })
        );
        // AFTER REQUEST SUCCESS
        addCase(drugDescriptionRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugDescriptionObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.isLoading = false;
                draftState.drugDescription = payload.htmlDesc;
            })
        );
        // AFTER REQUEST ERROR
        addCase(drugDescriptionRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isLoading = false;
                draftState.drugDescription = '';
            })
        );
    }
});

export const {
    setDrugListVisibility,
    setDrugLookupStatus,
    resetDrugLookupResults,
    resetDrugDescription,
    setLoadingDrugPrices
} = drugSlice.actions;

export default drugSlice.reducer;
