import classNames from 'classnames';
import { Field, Form } from 'formik';
import { ENABLE_PACKAGE_OPTIONS, USE_STATIC_FORMULARIES } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit
import FormSelect from 'ui-kit/form-select/form-select';
import Text from 'ui-kit/text/text';

// Components
import DiscountDrugModal from 'components/discount-drug-modal/discount-drug-modal';
import DrugLookupAlt from 'components/drug-lookup/drug-lookup-alt.component';
import DrugLookup from 'components/drug-lookup/drug-lookup.component';
import DrugPriceInfoCard from 'components/drug-price-info-card/drug-price-info-card.component';
import { PrescriptionInfoDetails } from 'components/prescription-info-details/prescription-info-details';
import { PrescriptionInfoDetailsAlt } from 'components/prescription-info-details/prescription-info-details-alt';

// State
import {
    accountAcCodeSelector,
    accountProfileMembershipSelector,
    accountProfilEPostPatientNumSelector
} from 'state/account/account.selectors';
import {
    DrugOption,
    PackageOption,
    setDrugDetailValues,
    setSelectedMember,
    StrengthOption,
    StrengthOptionAlt
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { addTransferPrescriptionDrugDetailsSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { drugDetailsLookupRoutine, drugFormLookupRoutine } from 'state/drug/drug.routines';
import { StrengthsObjectPayload } from 'state/drug/drug.services';
import { DosageFormsObjectPayload, DrugLookupObjectPayload, PackageOptionPayload } from 'state/drug/drug.services';
import { familyProfileFamilyPlansMapSelector } from 'state/family-profile/family-profile.selectors';

// Types
import { PrescriptionTypes } from 'types/prescription';

// Util
import { formatDrugName } from 'util/drug';
import { TrackError } from 'util/google_optimize/optimize_helper';
import { doesPlanAllowsPricing } from 'util/pricing';

// Custom Hooks
import useBirdiPrice from 'hooks/useBirdiPrice';
import { useGlobalLink } from 'hooks/useGlobalLink';

import './drug-lookup-form.styles.scss';

// Extract the strength options from the payload.
export const extractStrengths = (strengths: StrengthsObjectPayload[]): StrengthOption[] => {
    if (Array.isArray(strengths)) {
        return strengths.map((strength: StrengthsObjectPayload): StrengthOption => {
            if (strength.fullStrength.trim() === '') {
                return {
                    key: 'N/A',
                    label: 'N/A',
                    value: 'N/A',
                    fullStrength: '',
                    quantity90days: '',
                    drugCode: '',
                    gpi: '',
                    packageOptions: [] // DRX-1056 enhancement
                };
            } else {
                return {
                    key: strength.fullStrength,
                    label: strength.fullStrength,
                    value: strength.fullStrength,
                    fullStrength: strength.fullStrength,
                    quantity90days: strength.quantity90days ? strength.quantity90days.toString() : '',
                    drugCode: strength.ndc,
                    gpi: strength.gpi,
                    packageOptions: strength.packageOptions // DRX-1056 enhancement
                };
            }
        });
    }

    return [];
};

// DRX-1056 enhancement
export const extractPackageOptions = (strength: any[] | StrengthOption | StrengthOptionAlt): PackageOption[] => {
    if (strength && strength.packageOptions && Array.isArray(strength.packageOptions)) {
        return strength.packageOptions.map((pkg: PackageOptionPayload): PackageOption => {
            const pkgDisplay =
                pkg.packageDisplay === null ||
                pkg.packageDisplay === undefined ||
                pkg.packageDisplay?.toLowerCase() === 'null'
                    ? `(Units)`
                    : pkg.packageDisplay;
            const pkgDescription =
                pkg.packageDesc === null || pkg.packageDesc === undefined || pkg.packageDesc?.toLowerCase() === 'null'
                    ? `Units`
                    : pkg.packageDesc;
            return {
                key: pkg.ndc,
                value: pkg.ndc,
                /*  The packaging label content may change. This was the first option, and the working code below was discussed w/Peter. Pending approval.
                label:
                    pkg.packageQuantity === 1
                        ? `${pkg.packageSize}${pkg.packUoM} ${pkg.packageDesc}`
                        : `${pkg.packageSize}${pkg.packUoM} ${pkg.packageDesc} - ${pkg.packageDisplay} ${pkg.packageQuantity}`,
                */
                label:
                    pkg.packageQuantity === 1
                        ? `${pkg.packageSize} ${pkg.packUoM} ${pkg.packageDisplay}`
                        : `(${pkg.packageQuantity}) ${pkg.packageSize} ${pkg.packUoM} ${pkg.packageDesc} - ${pkg.packTotal} Total Package Size`,
                packageWithStrength: `${pkg.packTotal} of ${strength.label} (${pkg.packageDisplay})`,
                quantity90days: pkg.quantity90days.toString(),
                quantityLabelDesc: pkg.quantityLabelDesc,
                isUoU: pkg.isUoU,
                packageDesc: pkgDescription,
                packageDisplay: pkgDisplay,
                drugCode: pkg.ndc
            };
        });
    }

    return [];
};

interface DrugLookupFormProps {
    formName: string;
    formik: any;
    isFormValid: boolean;
    isControlled?: boolean | undefined;
    accountHasInsurance?: boolean | undefined;
    currentFlowType?: string | undefined;
    setIsControlled?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
    setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
    setFormError: React.Dispatch<React.SetStateAction<string>>;
    handleReset: () => void;
    variant?: 'gbp' | 'new-transfer';
    className?: string;
    isDrugNameEditable: boolean;
    footer?: () => void;
    drugSelectOptions?: object;
    formError?: string | undefined;
    forceLegacyPrescriptionInfo?: boolean;
    isCaregiver?: boolean;
    familyMembers?: Record<string, any>[];
}

const DrugLookupForm = ({
    isDrugNameEditable,
    drugSelectOptions,
    formName,
    formik,
    isFormValid,
    isControlled = false,
    accountHasInsurance = false,
    currentFlowType,
    formError,
    setIsControlled,
    setIsFormValid,
    setFormError,
    handleReset,
    variant = 'new-transfer',
    className = '',
    footer,
    forceLegacyPrescriptionInfo = false,
    isCaregiver,
    familyMembers
}: DrugLookupFormProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const globalLink = useGlobalLink();
    const { getPrescriptionPrice } = useBirdiPrice();

    const classes = classNames('drug-lookup-form', 'drug-lookup-form--' + variant, className);

    // Selectors
    const requestFormValues = useSelector(addTransferPrescriptionDrugDetailsSelector);
    const { hasMembership } = useSelector(accountProfileMembershipSelector);
    const familyPlanList: Record<string, string> = useSelector(familyProfileFamilyPlansMapSelector);
    const ownerPlan = useSelector(accountAcCodeSelector);
    const ownerPatientEpostNum = useSelector(accountProfilEPostPatientNumSelector);

    // State
    const [prescriptionDetails, setPrescriptionDetails] = useState<PrescriptionTypes | null>(null);
    const [isDrugPriceInfoCardHidden, setIsDrugPriceInfoCardHidden] = useState<boolean>(true);
    const [drugPrice, setDrugPrice] = useState<string>('');
    const [isBirdiSelect, setIsBirdiSelect] = useState<boolean>(false);
    const [isBRD02, setIsBRD02] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<string>(ownerPlan);

    // Reset the form, strength, and quantity values.
    const resetForm = (form: any) => {
        form.setFieldValue('drugForm', '');
        form.setFieldValue('drugDisplayName', '');
        form.setFieldValue('genericProductCode', '');
        form.setFieldValue('strength', '');
        form.setFieldValue('qty', '');
        form.setFieldTouched('drugForm', false);
        form.setFieldTouched('drugDisplayName', false);
        form.setFieldTouched('genericProductCode', false);
        form.setFieldTouched('strength', false);

        // DRX-1056 enhancement
        if (ENABLE_PACKAGE_OPTIONS) {
            form.setFieldValue('ndc', '');
            form.setFieldTouched('ndc', false);
            form.setFieldTouched('qty', false);
        }
        dispatch(
            setDrugDetailValues({
                drugFormOptions: [],
                drugStrengthOptions: [],
                drugPackageOptions: [] // DRX-1056 enhancement
            })
        );

        handleReset(form);
    };

    const handleDrugBlur = (form: any) => {
        // The field must be touched in order for the isControlled
        // error to display.
        globalLink.setLastFormField(t('prescriptionInfoForm.inputs.drugName'));
        if (isControlled) {
            form.setFieldTouched('drugName', true);
        }
    };

    // Handle a change in the drug name.
    // This is passed as a callback to the drug-lookup component.
    const handleDrugChange = (drug: DrugLookupObjectPayload, form: any) => {
        // First, reset form, strength, and quantity values.
        resetForm(form);

        // Hide drug price info card after resetting the prescription info.
        setIsDrugPriceInfoCardHidden(true);

        // Set the drugDisplayName which is a hidden field.
        form.setFieldValue('drugDisplayName', formatDrugName(t, drug));
        form.setFieldTouched('drugDisplayName', true);
        // Store the genericProductCode since drugName is no longer unique.
        form.setFieldValue('genericProductCode', drug.genericProductCode);
        form.setFieldTouched('genericProductCode', true);
        // If the selected drug is a controlled substance, display an error.
        const isControlledDrug = drug.deaClassCode === 'NA' ? false : true;
        if (isControlledDrug && setIsControlled !== undefined) {
            setIsControlled(true);
            setIsFormValid(false);
            // The field must be touched in order for the isControlled
            // error to display.
            form.setFieldTouched('drugName', true);
            return;
        }
        getDrugDetails(drug, form);
    };

    const updateDrugForms = (drugForms: DrugOption[], form: any) => {
        const pkgOptions = drugForms[0].strengths[0].packageOptions
            ? extractPackageOptions(drugForms[0].strengths[0])
            : [];
        // If there is only one drug form available, set it as the
        // selected value.
        dispatch(
            setDrugDetailValues({
                drugFormOptions: drugForms,
                drugStrengthOptions: drugForms[0].strengths,
                drugPackageOptions: pkgOptions
            })
        );
        if (drugForms.length === 1) {
            form.setFieldValue('drugForm', drugForms[0].dosageForm);

            // If there is only one strength value, set is as the
            // selected value.
            if (drugForms[0].strengths.length === 1) {
                if (drugForms[0].strengths[0].fullStrength.trim() === '') {
                    form.setFieldValue('strength', 'N/A');
                    form.setFieldValue('qty', '');
                } else {
                    form.setFieldValue('strength', drugForms[0].strengths[0].fullStrength);
                    form.setFieldValue('ndc', drugForms[0].strengths[0].drugCode);
                    if (drugForms[0].strengths[0].quantity90days) {
                        form.setFieldValue('qty', drugForms[0].strengths[0].quantity90days);
                    }
                    if (drugForms[0].strengths[0].gpi) {
                        form.setFieldValue('gpi', drugForms[0].strengths[0].gpi);
                    }

                    if (pkgOptions.length === 1) {
                        form.setFieldValue('ndc', pkgOptions[0].value);
                        form.setFieldValue('qty', pkgOptions[0].quantity90days);
                    }
                }
            }
        }
    };

    const formatDosageForms = (dosageForms: DosageFormsObjectPayload[]): DrugOption[] => {
        // Format the dosageForm into the right format
        return dosageForms.map((item: DosageFormsObjectPayload) => {
            const drugStrengths = extractStrengths(item.strengths);
            return {
                key: item.dosageForm,
                label: item.dosageForm,
                value: item.dosageForm,
                strengths: drugStrengths,
                dosageForm: item.dosageForm
            };
        });
    };

    // Given a new drug name from the drug lookup selector, perform the second
    // lookup to get the drugform details.
    const getDrugDetails = (drug: DrugLookupObjectPayload, form: any) => {
        if (drug.dosageForms && drug.dosageForms.length > 0) {
            updateDrugForms(formatDosageForms(drug.dosageForms), form);
        } else {
            if (ENABLE_PACKAGE_OPTIONS) {
                dispatch(
                    drugFormLookupRoutine.trigger({
                        drugName: drug.onSaleDrugName,
                        gpi: drug.genericProductCode,
                        onSuccess: (drugForms: any) => {
                            // Format the dosageForm into the right format
                            const cleanDrugForms = drugForms.map((item: DosageFormsObjectPayload) => {
                                const drugStrengths = extractStrengths(item.strengths);
                                return {
                                    key: item.dosageForm,
                                    label: item.dosageForm,
                                    value: item.dosageForm,
                                    strengths: drugStrengths,
                                    dosageForm: item.dosageForm
                                };
                            });
                            updateDrugForms(cleanDrugForms, form);
                        },
                        onFailure: () => {
                            TrackError(
                                'prescription-info-form.component',
                                'getDrugDetails',
                                t('prescriptionInfoForm.errors.drugDetailsNotFound')
                            );
                            setFormError(t('prescriptionInfoForm.errors.drugDetailsNotFound'));
                        }
                    })
                );
            } else {
                dispatch(
                    drugDetailsLookupRoutine.trigger({
                        drugName: drug.onSaleDrugName,
                        gpi: drug.genericProductCode,
                        onSuccess: (drugForms: any) => {
                            updateDrugForms(formatDosageForms(drugForms), form);
                        },
                        onFailure: () => {
                            TrackError(
                                'prescription-info-form.component',
                                'getDrugDetails',
                                t('prescriptionInfoForm.errors.drugDetailsNotFound')
                            );
                            setFormError(t('prescriptionInfoForm.errors.drugDetailsNotFound'));
                        }
                    })
                );
            }
        }
    };

    const handleDrugPriceChange = (birdiPrescription: PrescriptionTypes) => {
        setIsDrugPriceInfoCardHidden(true);

        if (birdiPrescription && birdiPrescription.strength !== '' && currentFlowType === 'Transfer') {
            let prescription = birdiPrescription;
            const selectedStrength = requestFormValues.drugStrengthOptions.find(
                (strength) => strength.key === birdiPrescription.strength
            );

            if (birdiPrescription.ndc === '') {
                prescription = { ...prescription, ndc: selectedStrength?.packageOptions[0].ndc };
            }

            if (birdiPrescription.strengthWithPackage === '' && selectedStrength?.packageOptions[0].isUoU === 1) {
                const packTotal = selectedStrength?.packageOptions[0].packTotal;
                const packDisplay = selectedStrength?.packageOptions[0].packageDisplay;

                prescription = { ...prescription, strengthWithPackage: `${packTotal} ${packDisplay}` };
            }
            if (familyMembers && familyMembers?.length <= 1) {
                prescription.dependentEpostPatientNum = ownerPatientEpostNum;
            }

            getPrescriptionPrice({
                values: prescription,
                handlePriceSuccess: (price: string) => {
                    setPrescriptionDetails(prescription);
                    setDrugPrice(price);
                    setIsDrugPriceInfoCardHidden(false);
                },
                handleIsBirdiSelect: setIsBirdiSelect,
                handleIsBrd02: setIsBRD02
            });
        }
    };

    const handleDependentChange = (event: any) => {
        formik.setFieldValue('dependentEpostPatientNum', event.option.value);
        const selectedPlan = familyPlanList[String(event.option.value)] || ownerPlan;
        dispatch(setSelectedMember(event.option.value));
        setCurrentPlan(selectedPlan);
        if (!isDrugPriceInfoCardHidden && prescriptionDetails) {
            handleDrugPriceChange(prescriptionDetails);
        }
    };

    return (
        <Form
            id="prescription-info-form"
            data-ga-form-name={formName}
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className={classes}
        >
            {variant === 'new-transfer' && (
                <p className="form-instructions">{t('prescriptionInfoForm.labels.drugName')}</p>
            )}
            {isCaregiver && familyMembers && familyMembers?.length > 1 && (
                <Field
                    name="dependentEpostPatientNum"
                    label={t('prescriptionInfoForm.inputs.patientName')}
                    options={familyMembers}
                    placeholder={t('prescriptionInfoForm.inputs.patientName')}
                    errors={
                        formik.errors?.dependentEpostPatientNum
                            ? t('forms.errorMessages.requiredField', {
                                  label: t('prescriptionInfoForm.inputs.patientName')
                              })
                            : undefined
                    }
                    component={FormSelect}
                    className="m-0"
                    touched={formik.touched.dependentEpostPatientNum}
                    value={formik.values?.dependentEpostPatientNum}
                    defaultValue={formik.initialValues.dependentEpostPatientNum}
                    formikControlled={true}
                    onChange={handleDependentChange}
                />
            )}

            <Row>
                <Col className="DrugLookup">
                    <Text
                        className={'d-none'}
                        type={'hidden'}
                        name="imageUrl"
                        onChange={formik.handleChange}
                        touched={formik.touched.imageUrl}
                        value={formik.values?.imageUrl}
                        defaultValue={formik.initialValues.imageUrl}
                        maxLength={1000}
                    />
                    <Text
                        className={'d-none'}
                        type={'hidden'}
                        name="drugDisplayName"
                        onChange={formik.handleChange}
                        touched={formik.touched.drugDisplayName}
                        value={formik.values?.drugDisplayName}
                        defaultValue={formik.initialValues.drugDisplayName}
                    />
                    <Text
                        className={'d-none'}
                        type={'hidden'}
                        name="genericProductCode"
                        onChange={formik.handleChange}
                        touched={formik.touched.genericProductCode}
                        value={formik.values?.genericProductCode}
                        defaultValue={formik.initialValues.genericProductCode}
                    />
                    <Text
                        className={'d-none'}
                        type={'hidden'}
                        name="gpi"
                        onChange={formik.handleChange}
                        touched={formik.touched.gpi}
                        value={formik.values?.gpi}
                        defaultValue={formik.initialValues.gpi}
                    />
                    {isDrugNameEditable && (
                        <Field
                            name="drugName"
                            label={t('prescriptionInfoForm.inputs.drugName')}
                            component={USE_STATIC_FORMULARIES ? DrugLookupAlt : DrugLookup}
                            formError={formError}
                            errors={
                                formik.errors?.drugName
                                    ? isControlled && formik.values.drugName !== ''
                                        ? t('prescriptionInfoForm.errors.controlledSubstance', {
                                              label: t('prescriptionInfoForm.inputs.drugName')
                                          })
                                        : t('forms.errorMessages.requiredField', {
                                              label: t('prescriptionInfoForm.inputs.drugName')
                                          })
                                    : undefined
                            }
                            className="m-0"
                            touched={formik.touched.drugName}
                            value={formik.values?.drugName}
                            defaultValue={formik.initialValues.drugName}
                            onDrugChange={handleDrugChange}
                            onDrugBlur={handleDrugBlur}
                            onResetForm={resetForm}
                            onDrugLookupSearchFail={() => {
                                setFormError(t('prescriptionInfoForm.errors.drugNotFound'));
                            }}
                        />
                    )}
                    <DiscountDrugModal
                        accountHasInsurance={accountHasInsurance}
                        location="TransferRx"
                        isMembershipUser={hasMembership}
                    />
                </Col>
            </Row>
            {currentFlowType === 'New' && (
                <p className="form-instructions">{t('prescriptionInfoForm.labels.rxDetails')}</p>
            )}
            {ENABLE_PACKAGE_OPTIONS && !forceLegacyPrescriptionInfo ? (
                <PrescriptionInfoDetailsAlt
                    formik={formik}
                    extractStrengths={extractStrengths}
                    selectOptions={drugSelectOptions}
                    onQuantityChange={handleDrugPriceChange}
                />
            ) : (
                <PrescriptionInfoDetails
                    formik={formik}
                    extractStrengths={extractStrengths}
                    selectOptions={drugSelectOptions}
                />
            )}
            {currentFlowType === 'Transfer' && (
                <>
                    <Text
                        name="rxNumber"
                        label={t('prescriptionInfoForm.inputs.rxNumber')}
                        onChange={formik.handleChange}
                        errors={
                            formik.errors?.rxNumber
                                ? t('forms.errorMessages.requiredField', {
                                      label: t('prescriptionInfoForm.inputs.rxNumber')
                                  })
                                : undefined
                        }
                        touched={formik.touched.rxNumber}
                        value={formik.values?.rxNumber}
                        defaultValue={formik.initialValues.rxNumber}
                        disabled={isControlled}
                        autocomplete="off"
                        onFocus={() => globalLink.handleFieldFocus(t('prescriptionInfoForm.inputs.rxNumber'))}
                    />

                    {!isDrugPriceInfoCardHidden && doesPlanAllowsPricing(currentPlan) && (
                        <DrugPriceInfoCard
                            prescription={prescriptionDetails}
                            price={drugPrice}
                            isBRD02={isBRD02 || false}
                            isBirdiSelect={isBirdiSelect}
                        />
                    )}
                </>
            )}

            {footer && (
                <Row>
                    <Col>{footer()}</Col>
                </Row>
            )}
        </Form>
    );
};

export default DrugLookupForm;
