import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import ChangePaymentMethod from 'components/change-payment-method/change-payment-method';
import { ChangePaymentMethodProps } from 'components/change-payment-method/change-payment-method.props';
import { ModalComponentFooter, ModalComponentHeader } from 'components/modal/modal.component';
import { ModalComponentProps } from 'components/modal/modal.props';
import { PaymentForm } from 'components/payment-form';

import {
    accountAddPaymentRoutine,
    accountGetAllCreditCardsRoutine,
    accountGetPaymetricDetailsRoutine,
    accountGetTokenizedCardNumberRoutine
} from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountIsMembershipSelector,
    accountProfileSelector,
    paymetricDetailsSelector
} from 'state/account/account.selectors';
import { ProfileAddPaymentObjectPayload } from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    easyRefillAddPaymentCardsRoutine,
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillPaymetricDetailsRoutine,
    easyRefillTokenizedCardNumberRoutine
} from 'state/easy-refill/easy-refill.routines';
import {
    easyRefillEpostPatientNumSelector,
    easyRefillPaymentCardsSelector,
    easyRefillPaymetricDetailsSelector
} from 'state/easy-refill/easy-refill.selectors';
import { closeModalComponent, openModalComponent, setModalStep } from 'state/modal/modal.reducer';

import { PaymentMapper } from './payment-add-new.mapper';
import { PaymentAddNewProps } from './payment-add-new.props';
import './payment-add-new.styles.scss';

//@MARK: Accept both payments from easyRefill and account
export const PaymentAddNew = ({ creditCard, mode, isOnModal, currentFlow }: PaymentAddNewProps) => {
    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const easyRefillPatientNum = useSelector(easyRefillEpostPatientNumSelector);
    const easyRefillpaymentData = useSelector(easyRefillPaymentCardsSelector);
    const accountPaymentData = useSelector(accountCreditCardsSelector);
    const easyRefillPaymetricDetails = useSelector(easyRefillPaymetricDetailsSelector);
    const paymetricDetails = useSelector(paymetricDetailsSelector);
    const hasMembership = useSelector(accountIsMembershipSelector);
    const accountProfile = useSelector(accountProfileSelector);

    const handleEasyRefillPayment = (values: ProfileAddPaymentObjectPayload) => {
        const cardData = window.$XIPlugin.createJSRequestPacket(
            easyRefillPaymetricDetails!.merchantId,
            easyRefillPaymetricDetails!.accessToken
        );
        cardData.addField(window.$XIPlugin.createField('SecureCardNumber', true, values.cardNumber));

        window.$XIPlugin.ajax({
            url: easyRefillPaymetricDetails!.serviceUrl + 'Ajax',
            global: false,
            type: 'POST',
            data: cardData,
            success: function () {
                dispatch(
                    easyRefillTokenizedCardNumberRoutine.trigger({
                        onSuccess: (pciToken: string) => {
                            const creditCard = PaymentMapper(values, 'easyRefill', {
                                epostPatientNum: easyRefillPatientNum,
                                pciToken,
                                hasPaymentData: easyRefillpaymentData.length > 0
                            });
                            dispatch(
                                easyRefillAddPaymentCardsRoutine({
                                    creditCard,
                                    onSuccess: () => {
                                        dispatch(closeModalComponent());
                                        dispatch(
                                            openModal({
                                                showClose: true,
                                                contentClassName: 'prescription-add-payment-modal',
                                                size: 'xl',
                                                bodyContent: (
                                                    <BirdiModalContent
                                                        icon="default"
                                                        title={t('modals.addPaymentModal.sucess.title')}
                                                        body={t('modals.addPaymentModal.sucess.description')}
                                                    />
                                                ),
                                                ctas: [
                                                    {
                                                        onClick: () => {
                                                            dispatch(closeModal({}));
                                                        },
                                                        label: t('modals.addPaymentModal.sucess.cta'),
                                                        variant: 'primary'
                                                    }
                                                ]
                                            })
                                        );
                                    }
                                })
                            );
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: false,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            headerText={t('modals.addPaymentModal.error.title')}
                                            icon="alert"
                                        />
                                    ),
                                    bodyContent: (
                                        <BirdiModalContentAlt
                                            subTitle={t('modals.addPaymentModal.error.description')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            label: t('modals.addPaymentModal.error.cta'),
                                            variant: 'primary'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            },
            error: function () {
                dispatch(
                    openModal({
                        showClose: false,
                        type: 'danger',
                        size: 'lg',
                        headerContent: (
                            <BirdiModalHeaderDanger headerText={t('modals.addPaymentModal.error.title')} icon="alert" />
                        ),
                        bodyContent: <BirdiModalContentAlt subTitle={t('modals.addPaymentModal.error.description')} />,
                        ctas: [
                            {
                                onClick: () => {
                                    dispatch(closeModal({}));
                                },
                                label: t('modals.addPaymentModal.error.cta'),
                                variant: 'primary'
                            }
                        ]
                    })
                );
            }
        });
    };

    const handlePaymentForm = (values: ProfileAddPaymentObjectPayload) => {
        const cardData = window.$XIPlugin.createJSRequestPacket(
            paymetricDetails!.MerchantId,
            paymetricDetails!.AccessToken
        );
        cardData.addField(window.$XIPlugin.createField('SecureCardNumber', true, values.cardNumber));

        window.$XIPlugin.ajax({
            url: paymetricDetails!.ServiceUrl + 'Ajax',
            global: false,
            type: 'POST',
            data: cardData,
            success: function () {
                dispatch(
                    accountGetTokenizedCardNumberRoutine.trigger({
                        onSuccess: (pciToken: string) => {
                            const creditCard = PaymentMapper(values, 'account', {
                                epostPatientNum: accountProfile?.epostPatientNum,
                                pciToken,
                                hasMembership,
                                hasPaymentData: accountPaymentData ? accountPaymentData.length > 0 : false
                            });

                            dispatch(
                                accountAddPaymentRoutine({
                                    ...creditCard,
                                    onSuccess: () => handleSuccess(),
                                    onFailure: () => handlePaymentFailure()
                                })
                            );
                        },
                        onFailure: () => handlePaymentFailure()
                    })
                );
            },
            error: function () {
                handlePaymentFailure();
            }
        });
    };

    const handleSuccess = () => {
        setLoading(false);
        dispatch(closeModalComponent());
        dispatch(
            openModal({
                showClose: true,
                isModalCentered: true,
                contentClassName: 'prescription-add-payment-modal',
                size: 'xl',
                bodyContent: (
                    <BirdiModalContent
                        icon="default"
                        title={t('modals.addPaymentModal.sucess.title')}
                        body={t('modals.addPaymentModal.sucess.description')}
                    />
                ),
                ctas: [
                    {
                        onClick: () => {
                            dispatch(closeModal({}));
                            if (currentFlow === 'payment-history') {
                                openChangePaymentModal();
                                dispatch(setModalStep({ modalStep: 1 }));
                            } else if (currentFlow === 'request-visit' || currentFlow === 'manage-membership') {
                                dispatch(closeModalComponent());
                                if (accountPaymentData && accountPaymentData?.length > 1)
                                    return openChangePaymentModal();
                            } else {
                                dispatch(closeModalComponent());
                            }
                        },
                        label: t('modals.addPaymentModal.sucess.cta'),
                        variant: 'primary'
                    }
                ],
                onClose() {
                    dispatch(closeModal({}));

                    if (currentFlow === 'payment-history') {
                        dispatch(setModalStep({ modalStep: 1 }));
                    } else if (currentFlow === 'request-visit' || currentFlow === 'manage-membership') {
                        dispatch(closeModalComponent());
                        if (accountPaymentData && accountPaymentData?.length > 1) return openChangePaymentModal();
                    } else {
                        dispatch(closeModalComponent());
                    }
                }
            })
        );
    };

    const handlePaymentFailure = () => {
        dispatch(closeModalComponent());
        setLoading(false);
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.addPaymentModal.error.title')} icon="alert" />
                ),
                bodyContent: <BirdiModalContentAlt subTitle={t('modals.addPaymentModal.error.description')} />,
                ctas: [
                    {
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        label: t('modals.addPaymentModal.error.cta'),
                        variant: 'primary'
                    }
                ]
            })
        );
    };

    const handleFormSubmit = async (values: ProfileAddPaymentObjectPayload) => {
        if (window !== undefined && window.$XIPlugin !== undefined) {
            if (mode === 'easyRefill') {
                handleEasyRefillPayment(values);
            } else {
                handlePaymentForm(values);
            }
        } else {
            console.log('No XIPlugin found');
        }
    };

    const openChangePaymentModal = () => {
        const modalProps: Partial<ModalComponentProps> = {
            title: t('pages.profile.membership.manageMembership.modals.changePaymentTitle'),
            hasDefaultFooter: false,
            hasCustomContent: true,
            hasModalHeader: false,
            hasCustomHeader: true,
            variation: 'small',
            isCentered: true,
            backdrop: 'static',
            modalStep: 1,
            onClose: () => dispatch(closeModalComponent())
        };

        const contentProps: ChangePaymentMethodProps = {
            isModal: true,
            isMultipleStep: true,
            currentFlow,
            ...(currentFlow === 'request-visit' && {
                modalSubtitle: t('pages.profile.membership.manageMembership.modals.changePaymentSubtitle')
            })
        };

        dispatch(
            openModalComponent({
                ...modalProps,
                content: <ChangePaymentMethod {...contentProps} />
            })
        );
    };

    const paymentFormRef = useRef<{
        submitForm: () => Promise<ProfileAddPaymentObjectPayload>;
        isValid: boolean;
    } | null>(null);

    const handleSubmit = async () => {
        if (paymentFormRef.current) {
            await paymentFormRef.current.submitForm();
            if (paymentFormRef.current.isValid) {
                setLoading(true);
            }
        }
    };

    useEffect(() => {
        if (mode === 'account') {
            dispatch(accountGetPaymetricDetailsRoutine.trigger());
            if (!accountPaymentData) dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
    }, [accountPaymentData, dispatch, mode]);

    useEffect(() => {
        if (mode === 'easyRefill') {
            dispatch(easyRefillPaymetricDetailsRoutine.trigger());
            if (!easyRefillpaymentData) dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
        }
    }, [easyRefillpaymentData, dispatch, mode]);

    useEffect(() => {
        const loadScript = (scriptUrl: string) => {
            const script = document.createElement('script');
            script.src = scriptUrl;
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (!window.$XIPlugin) {
                    console.error('Failed to load $XIPlugin');
                }
            };

            return () => {
                document.body.removeChild(script);
            };
        };

        if (paymetricDetails?.ScriptUrl) {
            return loadScript(paymetricDetails.ScriptUrl);
        } else if (easyRefillPaymetricDetails?.scriptUrl) {
            return loadScript(easyRefillPaymetricDetails.scriptUrl);
        }
    }, [paymetricDetails, easyRefillPaymetricDetails]);

    return (
        <div className="payment-add-new">
            {!isOnModal && (
                <ModalComponentHeader
                    hasDefaultTitle
                    isCloseable
                    hasTitleNavigation={false}
                    title={t('pages.profile.membership.manageMembership.addNewPayment')}
                    onClose={() => {
                        dispatch(closeModalComponent());
                    }}
                />
            )}

            <div className="payment-add-new__form">
                <PaymentForm ref={paymentFormRef} creditCard={creditCard} onSubmit={handleFormSubmit} />
            </div>

            <ModalComponentFooter
                cancelButtonLabel={t('modals.addNewPaymentMethod.buttons.cancel')}
                onCancel={() => dispatch(closeModalComponent())}
                continueButtonLabel={t('modals.addNewPaymentMethod.buttons.save')}
                onContinue={handleSubmit}
                isCTABusy={isLoading}
                isCTADisabled={isLoading}
            />
        </div>
    );
};
