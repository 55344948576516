import { createSelector } from '@reduxjs/toolkit';

import { accountDefaultAddressZipCodeSelector, accountProfileSelector } from 'state/account/account.selectors';
import { ProfileObjectPayload } from 'state/account/account.services';
import { Dependent } from 'state/family-profile/family-profile.reducers';
import { membershipPlanTypeSelector } from 'state/membership/membership.selector';
import { MembershipPlanId } from 'state/membership/membership.selector';
import { RootState } from 'state/store';

import { DependentsPricingData } from 'types/family-account';

import { BIRDI_PLANS } from 'enums/plans';

import { mapCaregiverAndFamilyMembers } from 'util/depentent';
import { isOnDemandPlan } from 'util/plans';

export const familyProfileSelector = (state: RootState) => state.familyProfileReducer;

export const familyProfileIsLoadingSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.isFamilyAccountLoading
);

export const dependentsApiStatusSelector = createSelector(
    familyProfileSelector,
    ({ dependentsApiStatus }) => dependentsApiStatus
);

export const familyProfileDependentsSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.dependents
);

export const familyProfileFamilyPlansMapSelector = createSelector(familyProfileSelector, (profileAccount) => {
    return profileAccount.dependents.reduce((acc, dependent) => {
        return {
            ...acc,
            [dependent.ePostPatientNum]: dependent.planAlias
        };
    }, {});
});

export const familyProfileCaregiversSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.caregivers
);

export const familyIsCaregiverLoadingSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.isCaregiverLoading
);

export const familyProfileDependentToAddSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.dependentToAdd
);

export const familyProfileDependentsActiveSelector = createSelector(familyProfileSelector, (profileAccount) =>
    profileAccount.dependents.filter((dependent) => !dependent.dateRevokeAccess)
);

export const familyMembersPlansSelector = createSelector(
    [familyProfileDependentsSelector, accountProfileSelector],
    (familyMembersData, profileObject) => {
        if (familyMembersData.length > 0) {
            const familyMembers = mapCaregiverAndFamilyMembers(
                profileObject as ProfileObjectPayload,
                familyMembersData,
                true
            );
            return familyMembers;
        } else {
            return null;
        }
    }
);

export const removeMemberIsLoadingSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.isRemoveMemberLoading
);

export const sendInvitationIsLoadingSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.isResendInvitationLoading
);

export const familyMembersPricingDataSelector = createSelector(
    [
        familyProfileDependentsActiveSelector,
        accountProfileSelector,
        accountDefaultAddressZipCodeSelector,
        membershipPlanTypeSelector
    ],
    (familyMembersData, profileObject, profileZipCode, accountPlanType) => {
        const ownerPlanType = (accountPlanType as MembershipPlanId) ?? MembershipPlanId.NONE;
        const isProfileLoaded = profileObject && profileObject?.epostPatientNum;
        const isMembershipLoaded =
            profileObject?.planAlias === BIRDI_PLANS.BRD_02 ? ownerPlanType !== MembershipPlanId.NONE : true;
        if (!isProfileLoaded || !isMembershipLoaded) return [];

        const accountPricingData: DependentsPricingData[] = [
            {
                planAlias: profileObject?.planAlias,
                zipCode: profileZipCode,
                planType: ownerPlanType,
                epostPatientNum: profileObject?.epostPatientNum,
                isOnDemandPlan: isOnDemandPlan(ownerPlanType)
            }
        ];
        if (familyMembersData.length > 0) {
            const familyMembersPricingData: DependentsPricingData[] = familyMembersData
                .filter(
                    (member: Dependent) => member.accountStatus !== 'expired' && member.accountStatus !== 'declined'
                )
                .map((member: Dependent) => {
                    const { planAlias, defaultZipCode, membershipPlanId, ePostPatientNum } = member;
                    const dependentPlanType = (membershipPlanId as MembershipPlanId) ?? MembershipPlanId.NONE;
                    return {
                        planAlias: planAlias as BIRDI_PLANS,
                        zipCode: defaultZipCode,
                        planType: dependentPlanType,
                        epostPatientNum: ePostPatientNum,
                        isOnDemandPlan: isOnDemandPlan(dependentPlanType)
                    };
                });
            return [...accountPricingData, ...familyMembersPricingData];
        } else {
            return accountPricingData;
        }
    }
);
