// General
import { navigate } from 'gatsby';
// Hooks
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import Button from 'ui-kit/button/button';
import LoadingMessage from 'ui-kit/loading-message/loading-message';

import TermsAndConditionsContent from 'display-components/auto-refill-modal-contents/terms-and-conditions';

// Components, Helpers & UI Kit
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { accountFetchPlansRoutine } from 'state/account/account.routines';
import {
    accountHasInsuranceSelector,
    accountIsCaliforniaUserSelector,
    accountPlansSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { getCartRoutine, startCartRoutine, updateRefillLinesRoutine } from 'state/cart/cart.routines';
import {
    cartIsBusySelector,
    cartItemsSelector,
    cartOrderShippingAddressSelector,
    cartSelector
} from 'state/cart/cart.selectors';
import { drugsWithDiscountSelector } from 'state/drug/drug.selectors';
import { familyMembersPlansSelector } from 'state/family-profile/family-profile.selectors';
import {
    setMedicineCabinetActiveTab,
    setMedicineCabinetFilterTab,
    setMedicineCabinetIsBusy
} from 'state/medicine-cabinet/medicine-cabinet.reducers';
import {
    medicineCabinetGetAllPrescriptions,
    medicineCabinetLoadRoutine
} from 'state/medicine-cabinet/medicine-cabinet.routines';
import {
    medicineCabinetActiveTabSelector,
    medicineCabinetAutoRefillToggleBusySelector,
    medicineCabinetFilterTabSelector,
    medicineCabinetIsBusySelector,
    medicineCabinetIsLoadingSelector,
    medicineCabinetPopulatedSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { closeModalComponent } from 'state/modal/modal.reducer';

// Interfaces and Types
import { CartPayload } from 'types/cart';

// Utils
import { processCart, searchRxNumberInCart } from 'util/cart';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';
import { hasPlanAutoRefillFlag } from 'util/prescription';
import { lowercaseAndCapitalize } from 'util/string';

import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';
import { useAutoRefillToggle } from 'hooks/useAutoRefillToggle';
import useMedicineCabinetPrescriptions from 'hooks/useMedicineCabinetPrescriptions';
import usePrescriptionCards from 'hooks/usePrescriptionCards';

import PrescriptionTabs from './prescription-tabs/prescription-tabs.component';
import PrescriptionCard from './prescriptions-card/prescriptions-card.component';
import PrescriptionsCards from './prescriptions-cards/prescriptions-cards.component';
// Styles
import './prescriptions-list.style.scss';

// Main component interface
interface PrescriptionsListProps {
    pillImage: GatsbyTypes.Maybe<Pick<GatsbyTypes.File, 'id'>>;
}

// Main component
const PrescriptionsList: React.FC<PrescriptionsListProps> = ({ pillImage }) => {
    // --------------------
    // @MARK: Hooks
    // -------------------
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { resetForm, setPrescriptionFlowType } = useAddTransferPrescription();
    const { handleToggleAutoRefill } = useAutoRefillToggle();
    const { prescriptionCards, prescriptions } = usePrescriptionCards();
    useMedicineCabinetPrescriptions();

    // --------------------
    // @MARK: Selectors
    // -------------------

    // Drug Selectors
    const drugDiscountPrices = useSelector(drugsWithDiscountSelector);

    // Cart Selectors
    const cartItemsObject = useSelector(cartItemsSelector);
    const cartObject = useSelector(cartSelector);
    const cardOrderShippingAddress = useSelector(cartOrderShippingAddressSelector);

    // Account Selectors
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const profileObject = useSelector(accountProfileSelector);
    const isCaliforniaUser = useSelector(accountIsCaliforniaUserSelector);
    const autoRefillToggleBusy = useSelector(medicineCabinetAutoRefillToggleBusySelector);

    // Medicine cabinet Selectors
    const activeDependentTab = useSelector(medicineCabinetActiveTabSelector);
    // activePlanAlias is used to define if the price should be available for the current dependent
    const medicineCabinetPopulated = useSelector(medicineCabinetPopulatedSelector);
    const prescriptionsIsBusySelector = useSelector(medicineCabinetIsBusySelector);
    const isCartLoading = useSelector(cartIsBusySelector);
    const filterTab = useSelector(medicineCabinetFilterTabSelector);
    const medicineCabinetIsLoading = useSelector(medicineCabinetIsLoadingSelector);

    // Family Members Selectors
    const familyMembers = useSelector(familyMembersPlansSelector);

    // --------------------
    // @MARK: Local state
    // -------------------
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    // --------------------
    // @MARK: Memoized Variables
    // -------------------

    // TODO: Check if this can be moved to sagas instead
    const dependentTabs = useMemo(() => {
        if (!profileObject?.isCaregiver) return [];

        // If the user is a caregiver try to load the
        // dependents information from dependents API
        // but if cart loads data first, the dependents
        // list can be loaded from that array too.
        if (familyMembers && familyMembers.length > 0) {
            return familyMembers?.map((dependent, i) => ({
                ePostPatientNum: `${dependent.value}`,
                familyMemberName: dependent.label,
                id: i
            }));
        }
        if (cartObject && cartObject.length > 0) {
            return cartObject?.map((cart, i) => ({
                ePostPatientNum: `${cart.EpostPatientNum}`,
                familyMemberName: lowercaseAndCapitalize(`${cart.FirstName} ${cart.LastName}`),
                id: i
            }));
        }

        return [];
    }, [profileObject, familyMembers, cartObject]);

    // Filter Rx according to selection (all, expired and auto refill)
    const filteredRxs = useMemo(() => {
        if (prescriptionCards) {
            const prevPrescriptions = activeDependentTab
                ? prescriptionCards.filter(
                      (prescription) => activeDependentTab === prescription.fullRxItem.epostPatientNum
                  )
                : prescriptionCards;
            switch (filterTab) {
                case 'all':
                    return prevPrescriptions;
                case 'auto-refills':
                    return prevPrescriptions.filter(
                        (prescription) =>
                            prescription.fullRxItem.autoRefillEnabled === true &&
                            prescription.fullRxItem.autoRefillEligible === true
                    );
                case 'expired':
                    return prevPrescriptions.filter((prescription) => prescription.isExpired);
                default:
                    return prevPrescriptions;
            }
        }

        return null;
    }, [prescriptionCards, filterTab, activeDependentTab]);

    // --------------------
    // @MARK: Effects
    // -------------------

    useEffect(() => {
        if (profileObject !== undefined && (!accountPlans || accountPlans.length === 0)) {
            if (profileObject.isCaregiver && familyMembers) {
                dispatch(accountFetchPlansRoutine.trigger({ familyMembers }));
            } else if (profileObject.epostPatientNum && !profileObject.isCaregiver) {
                dispatch(accountFetchPlansRoutine.trigger({ familyMembers: [profileObject.epostPatientNum] }));
            }
            dispatch(setMedicineCabinetIsBusy(false));
        }
    }, [profileObject, accountPlans, familyMembers, dispatch]);

    // --------------------
    // @MARK: Callbacks
    // -------------------

    // Add to cart functions
    const handleAddToCartClick = useCallback(
        (rxNumber: string, epostPatientNum: string) => {
            dispatch(setMedicineCabinetIsBusy(true));
            let cartRoutineSwitch;
            let rxInCart = false;

            if (!cartItemsObject || cartItemsObject?.length === 0) {
                cartRoutineSwitch = startCartRoutine;
            } else {
                cartRoutineSwitch = updateRefillLinesRoutine;
                // Make sure item isn't already in cart, due to super-fast clicking. TODO: move to saga
                // cast alreadyInCart = orderObject.refillRxs.find(action.payload.rxNumber)
                if (cartItemsObject) {
                    rxInCart = searchRxNumberInCart(rxNumber, cartItemsObject);
                }
            }

            if (!rxInCart) {
                dispatch(
                    cartRoutineSwitch.trigger({
                        rxNumber: rxNumber,
                        epostPatientNum: epostPatientNum,
                        onFailure: () => {
                            dispatch(setMedicineCabinetIsBusy(false));
                            dispatch(
                                medicineCabinetGetAllPrescriptions({
                                    epostNumFamilyMember: activeDependentTab
                                })
                            );
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <BirdiModalContent
                                            icon={'alert'}
                                            title={t(`pages.medicineCabinet.messages.callbacks.error`)}
                                            body={t(`pages.medicineCabinet.messages.callbacks.errorMessage`)}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        },
                        onSuccess: (data: CartPayload[]) => {
                            const extendedCart = processCart(
                                data,
                                accountHasInsurance,
                                prescriptions,
                                drugDiscountPrices,
                                accountPlans,
                                undefined,
                                cardOrderShippingAddress?.zipcode || ''
                            );
                            TrackCheckoutStep({
                                stepName: 'add',
                                step: '1',
                                carts: extendedCart,
                                prescriptions,
                                t: t,
                                shippingCost: '0',
                                accountHasInsurance: accountHasInsurance
                            });

                            dispatch(
                                getCartRoutine.trigger({
                                    onSuccess: () => {
                                        dispatch(setMedicineCabinetIsBusy(false));
                                        dispatch(closeModal({}));
                                    }
                                })
                            );
                        }
                    })
                );
            } else {
                dispatch(setMedicineCabinetIsBusy(false));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            accountHasInsurance,
            activeDependentTab,
            cartItemsObject,
            drugDiscountPrices,
            prescriptions,
            cardOrderShippingAddress?.zipcode,
            accountPlans
        ]
    );

    // Function to prepare generic error modal
    const showGenericErrorModal = useCallback(() => {
        // DRX-4429: Close T&C modal component before rendering modal with error information
        dispatch(closeModalComponent());

        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                        icon="alert"
                    />
                ),
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t(`pages.medicineCabinet.messages.callbacks.autoRefillErrorMessage`)}
                        note={t(`pages.medicineCabinet.messages.callbacks.errorMessageNote`, {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })}
                    />
                ),
                ctas: [
                    {
                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    }, [dispatch, t]);

    // Handle Auto refill toggle click
    const onChangeAutoRefill = useCallback(
        (rxNumber: string, rxSeqNumber: string, autoRefillEnabled: boolean, isRenew?: boolean) => {
            const hasAnyRxAutoRefill = filteredRxs?.some((rx) => rx.autoRefillEnabled) || false;
            handleToggleAutoRefill(
                { rxNumber: rxNumber, rxSeqNum: rxSeqNumber },
                autoRefillEnabled,
                hasAnyRxAutoRefill,
                <TermsAndConditionsContent />,
                isRenew,
                showGenericErrorModal
            );
        },
        [handleToggleAutoRefill, t, showGenericErrorModal, filteredRxs]
    );

    // Show Rxs depending on the family member selected
    const handleTabItemClick = useCallback(
        async (tab: string, dependent: string) => {
            if (dependent !== activeDependentTab) {
                dispatch(
                    medicineCabinetLoadRoutine.trigger({
                        fetchRxSubStatus: true,
                        selectedTab: tab,
                        selectedDependent: dependent
                    })
                );
            }

            dispatch(setMedicineCabinetFilterTab(tab));
            dispatch(setMedicineCabinetActiveTab(dependent));
        },
        [activeDependentTab, dispatch]
    );

    return (
        <div className="prescription-list-v2">
            <PrescriptionTabs
                activeTab={filterTab}
                activeDependentTab={activeDependentTab}
                isPrescriptionsAvailable={prescriptionCards.length > 0}
                onTabItemChange={handleTabItemClick}
                hasAutoRefillFlag={hasPlanAutoRefillFlag(`${activeDependentTab}`, profileObject!)}
                dependents={dependentTabs}
                myEpostPatientNum={profileObject?.epostPatientNum}
            />

            {prescriptionCards.length > 0 ? (
                <>
                    <div className="prescription-list__cta-toggle">
                        <Button
                            label={
                                isCollapsed
                                    ? t('components.prescriptionsList.mobile.ctas.expandCards')
                                    : t('components.prescriptionsList.mobile.ctas.collapseCards')
                            }
                            type={'button'}
                            variant="text-blue"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                        />
                    </div>

                    {medicineCabinetPopulated ? (
                        <PrescriptionsCards isCollapsed={isCollapsed}>
                            {filteredRxs && filteredRxs.length > 0 ? (
                                filteredRxs
                                    .sort((a, b) => {
                                        if (a.inOrderCart !== b.inOrderCart) {
                                            return a.inOrderCart ? -1 : 1;
                                        }
                                        return a.sortOrder - b.sortOrder;
                                    })
                                    .map((prescription, index) => (
                                        <PrescriptionCard
                                            key={`medicine-cabinet-rx-card-v2-${prescription.rxNumber}-${index}`}
                                            isCollapsed={isCollapsed}
                                            {...prescription}
                                            autoRefillToggle={onChangeAutoRefill}
                                            isCaliforniaUser={isCaliforniaUser}
                                            autoRefillToggleBusy={autoRefillToggleBusy}
                                            planAllowsAutoRefill={
                                                profileObject
                                                    ? hasPlanAutoRefillFlag(
                                                          prescription.fullRxItem.epostPatientNum,
                                                          profileObject
                                                      )
                                                    : false
                                            }
                                            isAddingToCart={prescriptionsIsBusySelector || isCartLoading}
                                            addToCart={() => {
                                                handleAddToCartClick(
                                                    prescription.rxNumber,
                                                    prescription.fullRxItem.epostPatientNum
                                                );
                                            }}
                                        />
                                    ))
                            ) : filteredRxs?.length === 0 && filterTab === 'auto-refills' ? (
                                <>
                                    <div>{t('components.prescriptionsList.empty.autoRefills')}</div>
                                    <Button
                                        chevron="right"
                                        type="button"
                                        label={t('components.dashboardCtaBlock.titles.transferPre')}
                                        variant={'text-blue'}
                                        onClick={() => {
                                            resetForm();
                                            setPrescriptionFlowType({ flowType: 'Transfer' });
                                            navigate('/secure/prescription');
                                        }}
                                    />
                                </>
                            ) : filteredRxs?.length === 0 && filterTab === 'expired' ? (
                                <>
                                    <div>{t('components.prescriptionsList.empty.expired')}</div>
                                    <Button
                                        chevron="right"
                                        type="button"
                                        label={t('components.dashboardCtaBlock.titles.transferPre')}
                                        variant={'text-blue'}
                                        onClick={() => {
                                            resetForm();
                                            setPrescriptionFlowType({ flowType: 'Transfer' });
                                            navigate('/secure/prescription');
                                        }}
                                    />
                                </>
                            ) : (
                                !prescriptionCards?.length &&
                                !filteredRxs?.length &&
                                filterTab === 'all' && (
                                    <>
                                        <div>{t('components.prescriptionsList.empty.allPrescriptions')}</div>
                                        <Button
                                            chevron="right"
                                            type="button"
                                            label={t('components.dashboardCtaBlock.titles.transferPre')}
                                            variant={'text-blue'}
                                            onClick={() => {
                                                resetForm();
                                                setPrescriptionFlowType({ flowType: 'Transfer' });
                                                navigate('/secure/prescription');
                                            }}
                                        />
                                    </>
                                )
                            )}
                        </PrescriptionsCards>
                    ) : (
                        <LoadingMessage isVisible={true} text={t('pages.medicineCabinet.loading')} />
                    )}
                </>
            ) : medicineCabinetPopulated ? (
                <>
                    <div>{t('components.prescriptionsList.empty.allPrescriptions')}</div>

                    <Button
                        chevron="right"
                        type="button"
                        label={t('components.dashboardCtaBlock.titles.transferPre')}
                        variant={'text-blue'}
                        onClick={() => {
                            resetForm();
                            setPrescriptionFlowType({ flowType: 'Transfer' });
                            navigate('/secure/prescription');
                        }}
                    />
                </>
            ) : prescriptionCards.length === 0 && !medicineCabinetIsLoading ? (
                <>
                    <div>{t('components.prescriptionsList.empty.allPrescriptions')}</div>
                    <Button
                        chevron="right"
                        type="button"
                        label={t('components.dashboardCtaBlock.titles.transferPre')}
                        variant={'text-blue'}
                        onClick={() => {
                            resetForm();
                            setPrescriptionFlowType({ flowType: 'Transfer' });
                            navigate('/secure/prescription');
                        }}
                    />
                </>
            ) : (
                <LoadingMessage isVisible={true} text={t('pages.medicineCabinet.loading')} />
            )}
        </div>
    );
};

export default PrescriptionsList;
